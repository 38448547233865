import { ValueSetting } from './ValueSetting';

/**
 * Setting containing a boolean value.
 */
export class CheckboxSetting extends ValueSetting<boolean> {
    constructor(label: string, value: boolean = false) {
        super(label);
        this.setValue(value);
    }
}
