import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Setting } from 'src/app/Template/Settings/Setting';

@Component({
  selector: 'app-multisettingitem',
  templateUrl: './multisettingitem.component.html',
  styleUrls: ['./multisettingitem.component.scss']
})
export class MultiSettingItemComponent {
  @Input() setting: Setting;
  @Input() index: number;
  @Output() action = new EventEmitter<{
    action: MultiSettingAction;
    index: number;
  }>();

  moveUp() {
    // emit to parent to move setting up.
    this.action.emit({
      action: MultiSettingAction.MoveUp,
      index: this.index
    });
  }

  moveDown() {
    // emit to parent to move setting down.
    this.action.emit({
      action: MultiSettingAction.MoveDown,
      index: this.index,
    });
  }

  remove() {
    // emit to parent to remove setting.
    this.action.emit({
      action: MultiSettingAction.Remove,
      index: this.index
    });
  }

}

export enum MultiSettingAction {
  MoveUp,
  MoveDown,
  Remove,
}
