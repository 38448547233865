import { Component, Input } from '@angular/core';
import { CheckboxSetting } from '../../../Template/Settings/ValueSettings/CheckboxSetting';

@Component({
  selector: 'app-checkboxsetting',
  templateUrl: './checkboxsetting.component.html',
  styleUrls: ['./checkboxsetting.component.scss']
})
export class CheckboxSettingComponent {
  @Input() setting: CheckboxSetting;

  getValue(): boolean {
    // get boolean value
    return this.setting.value as boolean;
  }

  setValue() {
    // switch boolean value from true to false or false to true
    this.setting.setValue(!this.getValue());
  }
}
