import { Component, Input } from '@angular/core';
import { NumberSetting } from 'src/app/Template/Settings/ValueSettings/NumberSetting';

@Component({
  selector: 'app-numbersetting',
  templateUrl: './numbersetting.component.html',
  styleUrls: ['./numbersetting.component.scss']
})
export class NumberSettingComponent {
  @Input() setting: NumberSetting;

  getNumber(): String {
    // get string value from number
    return (this.setting.value as number).toString();
  }

  setNumber(value: string) {
    // set value to parsed number from string
    this.setting.setValue(parseInt(value));
  }
}
