<div class="dialog card">
  <div class="card-header">
    <div class="d-inline-flex align-items-center w-100">
      <button
        type="button"
        style="background: none; height: auto"
        class="btn-close"
        (click)="back()"
      >
        <i class="fa-solid fa-arrow-left fa-lg"></i>
      </button>
      <h4 class="card-title">{{ header }}</h4>
    </div>
  </div>
  <div class="card-body">
    <app-setting
      *ngFor="let setting of settings"
      [setting]="setting"
      (OpenSettings)="openSettings($event)"
    ></app-setting>
  </div>
</div>
