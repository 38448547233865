export type TimeType = {
    hours: number;
    minutes: number;
    seconds: number;
}

export class Time {
    private TimeType: TimeType;

    constructor(hours: number, minutes: number, seconds: number) {
        this.TimeType = { hours: hours, minutes: minutes, seconds: seconds };
    }

    static fromMilliseconds(milliseconds: number): Time {
        // calculate hours, minutes and seconds from milliseconds.
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        // calculate remaining seconds and minutes from total seconds and minutes
        seconds = seconds % 60;
        minutes = minutes % 60;
        return new Time(hours, minutes, seconds);
    }

    toMilliseconds(): number {
        // calculate milliseconds from hours, minutes and seconds
        return (this.TimeType.hours * 3600 + this.TimeType.minutes * 60 + this.TimeType.seconds) * 1000;
    }

    setTime(hours: number, minutes: number, seconds: number) {
        this.TimeType.hours = hours;
        this.TimeType.minutes = minutes;
        this.TimeType.seconds = seconds;
    }

    getTime(): TimeType {
        return this.TimeType;
    }

    toString(): string {
        // initialize returnstring as empty string
        let returnstring: string = '';

        // add '0' prefix if needed to hours
        if (this.TimeType.hours < 10) {
            returnstring += '0' + this.TimeType.hours;
        } else {
            returnstring += this.TimeType.hours;
        }

        // add '0' prefix if needed to minutes
        if (this.TimeType.minutes < 10) {
            returnstring += ':0' + this.TimeType.minutes;
        } else {
            // add ':' seperating hours and minutes
            returnstring += ':' + this.TimeType.minutes;
        }

        // add '0' prefix if needed to seconds
        if (this.TimeType.seconds < 10) {
            returnstring += ':0' + this.TimeType.seconds;
        } else {
            // add ':' seperating minutes and seconds
            returnstring += ':' + this.TimeType.seconds;
        }
        return returnstring;
    }
}