import { FilterData, FilterType } from "src/template/FilterData";
import { ValueSetting } from '../../Settings/ValueSettings/ValueSetting';

export abstract class Filter {
    protected _filterName: string;
    protected _settings: ValueSetting<any>[] = [];

    protected _variant: FilterType;

    constructor() {
    }

    get variant(): FilterType {
        return this._variant;
    }

    get filterName(): string {
        return this._filterName;
    }

    get settings(): ValueSetting<any>[] {
        return this._settings;
    }

    toObject(): FilterData {
        let filterData: FilterData = {} as FilterData;

        // set the variant of the filter
        filterData.variant = this._variant;

        // set the settings of the filter
        this.writeSettings(filterData);

        return filterData;
    }

    protected abstract writeSettings(filterData: FilterData): void;
    
    abstract fromObject(filterData: FilterData): void;
}