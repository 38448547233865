import { Component, Input, OnInit } from '@angular/core';

import { VCAuthService, VCTemplateService } from '@gametailors/v-cilitator-angular';
import { Blank } from 'src/app/Template/BuildingBlocks/Blank';
import { BUILDING_BLOCKS, MANAGER_URL, MAX_BUILDING_BLOCKS } from 'src/app/objects/Constants';
import { Template } from '../../Template/Template';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
  // Template id to load
  @Input() templateId: string;

  // Boolean indicating if the template is loading/saving
  loading: boolean = true;
  saving: boolean = false;

  // Boolean indicating if the template is invalid
  invalidTemplate: boolean = false;

  template: Template = new Template().addBuildingBlockTypes(BUILDING_BLOCKS);
  manager_url: string = MANAGER_URL;

  constructor(
    public vca: VCAuthService,
    public vcTemplate: VCTemplateService,
  ) { }

  ngOnInit(): void {
    // load template.
    this.loadTemplate(this.templateId);
  }

  /**
   * Adds a blank block to the template.
   */
  addBlankBlock() {
    // do nothing if max buiding blocks is reached
    if (this.template.Count >= MAX_BUILDING_BLOCKS) {
      return;
    }
    // do not add black block if there is already one present
    if (this.template.buildingBlocks.some((block) => block instanceof Blank) == true) {
      return;
    }

    // add blank block.
    this.template.add(Blank);
  }

  // saves template to database
  saveTemplate(templateChoice: string) {
    // return if already saving
    if (this.saving) return;

    if (!this.template.checkValid()) {
      // Template is not valid
      alert("Template is not valid");
      return;
    } else {
      // Template is valid.
      console.log(this.template.toObject());

      var templateData = this.template.toObject();
      this.saving = true;

      // try to save template.
      this.vcTemplate.updateRoomTemplateContent("gofino", templateChoice, {
        dataPackages: { programming: templateData, public: "" }
      }, (result) => {
        // save completed
        this.saving = false;
        console.log(result);
        alert("Template saved");
      }, (error) => {
        // save failed
        this.saving = false;
        console.log(error);
        alert("Saving failed try again");
      });
    }
  }

  // loads template from database.
  loadTemplate(templateChoice: string) {
    // try to load template.
    this.vcTemplate.getRoomTemplate("gofino", templateChoice, (result) => {
      // load complete.
      console.log(result);

      // check if template is not empty.
      if (result.dataPackages.programming) {
        this.template.fromObject(result.dataPackages.programming);
      }
      this.loading = false;
    }, (error) => {
      // load failed.
      this.invalidTemplate = true;
      this.loading = false;
    });
  }
}
