import { FlowConnectionName } from "src/app/objects/Constants";
import { FlowConnectionData } from "src/template/FlowConnectionData";
import { Setting } from "../Settings/Setting";

/**
 * Abstract class for a flow connection between building blocks.
 */
export abstract class FlowConnection {
    protected abstract _name: FlowConnectionName;

    get name(): FlowConnectionName {
        return this._name;
    }

    /**
     * @returns Array of settings for the flow connection.
     */
    abstract getSettings(): Setting[];

    /**
     * Writes the value of the flow connection to an object containing the flow connection data.
     * @returns Object containing the flow connection data
     * @remark the source and target field are left empty and must be filled in by the caller.
     */
    abstract toObject(): FlowConnectionData;

    /**
     * Reads the value of the flow connection from an object containing the flow connection data.
     * @param flowConnectionObject - Object containing the flow connection data.
     */
    abstract fromObject(flowConnectionObject: FlowConnectionData): void;
}