<div class="dialog card">
  <div class="card-header">
    <h2 class="card-title">Select Building Block</h2>
  </div>
  <ul class="list-group list-group-flush">
    <a
      *ngFor="let buildingBlock of buildingBlocks"
      (click)="choice(buildingBlock.type)"
      style="cursor: pointer"
      ><li class="list-group-item">{{ buildingBlock.name }}</li></a
    >
  </ul>
</div>
