import { BuildingBlock } from "../BuildingBlocks/BuildingBlock";
import { HasSettings } from "../HasSettings";
import { ValueDropdownSetting } from "../Settings/ValueSettings/Options/ValueDropdownSetting";

import { FilterSetting } from "../Settings/ValueSettings/FilterSetting";
import { MultiSetting } from "../Settings/ValueSettings/MultiSetting";

import { FilterName, MAX_FILTERS } from "src/app/objects/Constants";
import { FilterData } from "src/template/FilterData";
import { InputData, ValueSettingData } from "src/template/SettingData";
import { SettingDropdownSetting } from "../Settings/ValueSettings/Options/SettingDropdownSetting";
import { BestFilter } from "./Filters/BestFilter";
import { EverythingFilter } from "./Filters/EverythingFilter";
import { MinFilter } from "./Filters/MinFilter";
import { RandomFilter } from "./Filters/RandomFilter";


/**
 * Object representing an input from another building block.
 */
export class BlockInput extends HasSettings {
    override readonly advandedSettingsHeader: string = "Input Settings";

    private _buildingBlockSetting: ValueDropdownSetting<string>;
    private _filtersSetting: MultiSetting<FilterData>;

    // function to get the building blocks before this input
    private getBuildingBlocksBefore: () => BuildingBlock[];

    constructor(getBuildingBlocksBefore: () => BuildingBlock[]) {
        super();
        this.getBuildingBlocksBefore = getBuildingBlocksBefore;

        // Drop down setting for choosing the building block
        this._buildingBlockSetting = new ValueDropdownSetting<string>("Building Block",
            () => this.getBuildingBlockOptions(),
            "Choose Building Block")

        // Multi setting for choosing the filters
        this._filtersSetting = new MultiSetting<FilterData>("Filters",
            (() => new SettingDropdownSetting<FilterData>("", [
                { key: FilterName.EVERYTHING, value: new FilterSetting("", new EverythingFilter()) },
                { key: FilterName.MINVOTES, value: new FilterSetting("", new MinFilter()) },
                { key: FilterName.RANDOM, value: new FilterSetting("", new RandomFilter()) },
                { key: FilterName.BEST, value: new FilterSetting("", new BestFilter()) },
            ], "Choose Filter"))).setBound(MAX_FILTERS);
    }

    get buildingBlockName(): string {
        // get the building block with the id of the selected option
        var buildingBlock = this.getBuildingBlocksBefore().find(block => block.id == this._buildingBlockSetting.value);

        // if the building block exists, return its name, otherwise return an empty string
        if (buildingBlock) {
            return buildingBlock.blockName;
        }
        return "";
    }

    get buildingBlockSet(): boolean {
        return this._buildingBlockSetting.valueSet;
    }

    get buildingBlockId(): string | undefined {
        return this._buildingBlockSetting.value;
    }

    override getMainSettings(): [] {
        return [];
    }

    override getAdvancedSettings(): [ValueDropdownSetting<string>, MultiSetting<FilterData>] {
        return [
            this._buildingBlockSetting,
            this._filtersSetting,
        ];
    }

    private getBuildingBlockOptions(): { key: string, value: string }[] {
        // get the building blocks before this input and map them to key-value pairs 
        // with the name of building block as key and the id as value
        var options = this.getBuildingBlocksBefore().map((buildingBlock): any => ({ key: buildingBlock.blockName, value: buildingBlock.id }));
        return options;
    }

    /**
     * Unsets the building block if it is not in the list of building blocks before this input.
     */
    updateBuildingBlock() {
        // if the building block is not in the list of building blocks before this input, 
        // unset it
        if (!this.getBuildingBlocksBefore().map(block => block.id).includes(this._buildingBlockSetting.value)) {
            this.unsetBuildingBlock();
        }
    }

    /**
     * Unsets the selected building block.
     */
    unsetBuildingBlock() {
        this._buildingBlockSetting.unset();
    }

    toObject(): InputData {
        // create input data object with empty building block.
        var result = {
            block: "",
            filters: this._filtersSetting.toObject(),
        }

        // if the building block is set, set the building block in the input data object.
        if (this._buildingBlockSetting.valueSet) {
            result.block = this._buildingBlockSetting.value;
        }
        return result;
    }

    fromObject(inputData: InputData) {
        // find the option with the id of the building block in the input data object.
        var option = this._buildingBlockSetting.options.find(option => option.value == inputData.block);

        // set the selected option to the option found if found.
        if (option != undefined) {
            this._buildingBlockSetting.selectedOption = option;
        }

        // set the filters from the input data object if there are any.
        if (inputData.filters !== undefined) {
            this._filtersSetting.fromObject(inputData.filters as ValueSettingData<ValueSettingData<FilterData>[]>);
        }
    }
}