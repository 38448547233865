import { NumberSetting } from '../../Settings/ValueSettings/NumberSetting';
import { ValueSetting } from '../../Settings/ValueSettings/ValueSetting';
import { Filter } from "./Filter";

import { FilterName } from "src/app/objects/Constants";
import { FilterType, RandomFilterData } from "src/template/FilterData";

// selects a random x cards from the input
export class RandomFilter extends Filter {
    protected override _filterName: string = FilterName.RANDOM;
    protected override _variant: FilterType.Random = FilterType.Random;

    protected override _settings: ValueSetting<any>[] = [
        new NumberSetting('x', 1) // Amount setting
    ];

    override writeSettings(filterData: RandomFilterData) {
        filterData.amount = this._settings[0].value;
    }

    fromObject(filterData: RandomFilterData) {
        this._settings[0].setValue(filterData.amount);
    }
}
