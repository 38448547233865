import { BlockVariant } from "src/template/BuildingBlockData";
import { BuildingBlockType } from "../Template/BuildingBlocks/BuildingBlock"
import { Start } from "../Template/BuildingBlocks/Start";
import { IndividualAnswers } from "../Template/BuildingBlocks/IndividualAnswers";
import { Voting } from "../Template/BuildingBlocks/Voting";
import { SectionDefiner } from "../Template/BuildingBlocks/SectionDefiner";
import { Pause } from "../Template/BuildingBlocks/Pause";
import { Discussion } from "../Template/BuildingBlocks/Discussion";

// Name of building block 
// Used as display name in UI
export enum BlockName {
    START = "Start",
    INDIVIDUAL_ANSWERS = "Individual Answers",
    BLANK = "Blank",
    VOTING = "Voting",
    DISCUSSION = "Discussion",
    SECTION_DEFINER = "Section Definer",
    PAUSE = "Pause",
}

// Name of filter
// Used as display name in UI
export enum FilterName {
    EVERYTHING = "Everything",
    BEST = "Best",
    MINVOTES = "MinVotes",
    RANDOM = "Random",
}


// Name of flow connection
// Used as display name in UI
export enum FlowConnectionName {
    FACILITATOR_APPROVES = "Facilitator Approves",
    TIMER = "Timer",
}

// List of all building blocks
export const BUILDING_BLOCKS: Array<[BlockVariant, BuildingBlockType]> = [
    [BlockVariant.START, Start],
    [BlockVariant.INDIVIDUAL_ANSWERS, IndividualAnswers],
    [BlockVariant.VOTING, Voting],
    [BlockVariant.SECTION_DEFINER, SectionDefiner],
    [BlockVariant.PAUSE, Pause],
    [BlockVariant.DISCUSSION, Discussion],
];

// Bounds for settings
export const MAX_BUILDING_BLOCKS = Infinity
export const MAX_FILTERS = Infinity
export const MAX_VOTING = Infinity
export const MAX_TIMER = Infinity
export const MAX_PARTICIPANTS = Infinity
export const MAX_ANSWERS = Infinity

// URL to manager
export const MANAGER_URL = "https://manager.gofino.jellezwie.rs/login"