<!--Navbar-->
<nav class="navbar navbar-light bg-light sticky-top">
  <div class="container-fluid">
    <span class="navbar-brand mb-0 h1">Builder</span>
    <a class="btn btn-outline-primary" href="{{environment.managerDomain}}" role="button">Manager</a>
  </div>
</nav>

<!-- Template -->
<app-main></app-main> 
