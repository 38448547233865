import { SettingValue } from 'src/template/SettingData';
import { ValueSetting } from '../ValueSetting';


export type option<T> = { key: string, value: T | (() => T) };

/**
 * Setting containing a value which is chosen from a dropdown menu.
 */
export abstract class DropdownSetting<T extends SettingValue> extends ValueSetting<T> {
    // is either an array of options or a function returning an array of options.
    private _options: option<T>[] | (() => option<T>[]);

    // currently selected option.
    private _selectedOption: option<T>;

    // placeholder text for dropdown shown when no option is selected.
    private _placeholder: string;

    constructor(label: string, options: option<T>[] | (() => option<T>[]),
                placeholder: string, selectedOption: string | undefined = undefined) {
        super(label);

        this._options = options;
        this._placeholder = placeholder;

        // select option if given.
        if (selectedOption != undefined) {
            // find option with given key.
            var option = this.options.find(option => option.key == selectedOption);

            if (option != undefined) {
                this.selectedOption = option;
            } else {
                // selected option not found.
                throw new Error("Option not found: " + selectedOption);
            }
        }
    }

    setValidConditionNonEmpty(): this {
        return this.setValidCondition(() => this.valueSet);
    }

    /**
     * Select option in dropdown with the given key.
     * @param key given key.
     */
    selectWithKey(key: string) {
        this.selectedOption = this.options.find(option => option.key == key)!;
    }

    /**
     * Unsets the value of this setting.
     */
    unset() {
        this._valueSet = false;
    }


    // getters and setters

    get options(): option<T>[] {
        if (typeof this._options === 'function') {
            return this._options();
        } else {
            return this._options;
        }
    }

    get selectedOption(): option<T> {
        return this._selectedOption;
    }

    get placeholder(): string {
        return this._placeholder;
    }

    set selectedOption(option: option<T>) {
        this._selectedOption = option;
        this.setValue(option.value);
    }
}
