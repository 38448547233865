import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HasSettings } from 'src/app/Template/HasSettings';
import { Setting } from 'src/app/Template/Settings/Setting';
import { CheckboxSetting } from 'src/app/Template/Settings/ValueSettings/CheckboxSetting';
import { FilterSetting } from 'src/app/Template/Settings/ValueSettings/FilterSetting';
import { SettingDropdownSetting } from 'src/app/Template/Settings/ValueSettings/Options/SettingDropdownSetting';
import { SettingValue } from 'src/template/SettingData';
import { InputSetting } from '../../../Template/Settings/InputSetting';
import { MultiSetting } from '../../../Template/Settings/ValueSettings/MultiSetting';
import { NumberSetting } from '../../../Template/Settings/ValueSettings/NumberSetting';
import { ValueDropdownSetting } from '../../../Template/Settings/ValueSettings/Options/ValueDropdownSetting';
import { TextSetting } from '../../../Template/Settings/ValueSettings/TextSetting';
import { TimeSetting } from '../../../Template/Settings/ValueSettings/TimeSetting';

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss']
})
export class SettingComponent {
    // setting to be displayed.
    @Input() setting: Setting;

    // boolean value to determine if label should be shown.
    @Input() label: boolean = true;

    // EventEmitters to emit to parent component to open advanced settings menu.
    @Output() OpenSettings = new EventEmitter<{
        settings: HasSettings;
    }>();

    /**
     * Functions for casting setting to specific setting type.
     */

    getTextSetting(): TextSetting {
        return this.setting as TextSetting;
    }

    getTimeSetting(): TimeSetting {
        return this.setting as TimeSetting;
    }

    getDropdownSetting(): ValueDropdownSetting<any> {
        return this.setting as ValueDropdownSetting<any>;
    }

    getMultiSetting(): MultiSetting<any> {
        return this.setting as MultiSetting<any>;
    }

    getNumberSetting(): NumberSetting {
        return this.setting as NumberSetting;
    }

    getInputSetting(): InputSetting {
        return this.setting as InputSetting;
    }

    getFilterSetting(): FilterSetting {
        return this.setting as FilterSetting;
    }

    getCheckboxSetting(): CheckboxSetting {
        return this.setting as CheckboxSetting;
    }

    getOptionSetting(): SettingDropdownSetting<SettingValue> {
        return this.setting as SettingDropdownSetting<SettingValue>;
    }
}

