import { FilterName } from "src/app/objects/Constants";
import { BestFilterData, FilterType } from "src/template/FilterData";
import { NumberSetting } from '../../Settings/ValueSettings/NumberSetting';
import { ValueSetting } from '../../Settings/ValueSettings/ValueSetting';
import { Filter } from "./Filter";


// Filter setting that is used to filter the input by the best x cards, where best means most voted.
export class BestFilter extends Filter {
    protected override _filterName: string = FilterName.BEST;
    protected override _variant: FilterType.Best = FilterType.Best;

    protected override _settings: ValueSetting<any>[] = [
        new NumberSetting('x', 1) // Amount setting
    ];

    override writeSettings(filterData: BestFilterData) {
        filterData.amount = this._settings[0].value;
    }

    fromObject(filterData: BestFilterData) {
        this._settings[0].setValue(filterData.amount);
    }
}