import { SettingData } from "src/template/SettingData";

/**
 * condition for a setting to be visible.
 * first element is the setting on which the condition is dependent.
 * second element is a function that returns a boolean given the first element.
 */
type SettingCondition<T extends Setting> = [T, (value: T) => boolean];

/**
 * Abstract class for a setting.
 */
export abstract class Setting {
    private readonly _label: string;
    private _exposed: boolean = false;
    private _visible: () => boolean = () => true;
    protected _validCondition: () => boolean = () => true;

    // settings that are exposed when this setting is exposed
    private _exposeDependencies: Setting[] = [];

    protected _valid: boolean = true;

    constructor(label: string) {
        this._label = label;
    }

    /**
     * Sets the condition for the setting to be valid.
     * @remarks The condition is checked when the setting is validated in CheckValid().
     * @param validCondition - Condition for the setting returning true if setting is valid.
     * @returns itself.
     */
    setValidCondition(validCondition: () => boolean): this {
        this._validCondition = validCondition;
        return this;
    }

    /**
     * Sets the visible condition of the setting.
     * @param settingConditions - List of conditions for the setting to be visible.
     * @returns itself.
     */
    setVisibleCondition(settingConditions: SettingCondition<any>[]) {
        // array of all conditions.
        var conditions: (() => boolean)[] = [];

        // add all conditions to array.
        for (let settingCondition of settingConditions) {
            conditions.push(() => settingCondition[1](settingCondition[0]));

            // add this setting to the expose settings of the setting on which the condition is dependent.
            settingCondition[0].addExposeDependency(this);
        }

        // _visible is true if all conditions are true.
        this._visible = () => conditions.every(condition => condition());
        return this;
    }

    /**
     * Adds link such that the setting is exposed when this setting is exposed.
     * @param setting - Setting that is exposed when this setting is exposed.
     */
    addExposeDependency(setting: Setting) {
        this._exposeDependencies.push(setting);
    }

    /**
     * Should be called when anything in the setting changes.
     */
    refreshValid(): void {
        this._valid = true;
    }

    /**
     * Reads the value of the setting from the given setting data.
     * @param settingData - Object containing the setting data
     */
    fromObject(settingData: SettingData<any>): void {
        this.exposed = settingData.exposed;
    }

    /**
     * Checks if the setting is valid.
     * @returns true if setting is valid, false otherwise.
     */
    checkValid(check: boolean = true): boolean {
        this._valid = this.exposed || (this._validCondition() && check);
        return this._valid;
    };

    // getters and setters

    get valid(): boolean {
        return this._valid;
    }

    get label(): string {
        return this._label;
    }

    get visible(): boolean {
        return this._visible();
    }

    get exposed(): boolean {
        return this._exposed;
    }

    set exposed(exposed: boolean) {
        if (exposed) {
            // expose all settings that are dependent on this setting
            for (let setting of this._exposeDependencies) {
                setting.exposed = true;
            }
        }
        this._exposed = exposed;
        this.refreshValid();
    }
}