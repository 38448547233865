<div *ngIf="!loading else templateLoading">
  <div *ngIf="!invalidTemplate else templateInvalid" class="container d-flex flex-column" style="max-width: 500px">
    <div *ngFor="
      let buildingBlockControler of template.buildingBlockContainers;
      let i = index
    " class="row">
      <app-buildingblock [buildingBlockController]="buildingBlockControler" [template]="template" [index]="i">
      </app-buildingblock>
    </div>
    <button class="btn btn-primary" (click)="addBlankBlock()">
      Add Building Block
    </button>

    <!-- <button class="btn btn-primary" [ngClass]="{active: saving}" (click)="saveTemplate(this.templateId)">
      {{saving ? "Saving..." : "Save Template" }}
    </button> -->

    <button *ngIf="!saving" class="btn btn-primary" class="btn btn-primary" [ngClass]="{active: saving}" (click)="saveTemplate(this.templateId)">
      Save Template
    </button>

    <button *ngIf="saving" class="btn btn-primary" type="button" disabled
      (click)="saveTemplate(this.templateId)">
      Save Template
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>


  </div>
</div>

<ng-template #templateLoading>
  <br><br><br>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <br>
  </div>
  <p style="text-align: center;">Retrieving template data...</p>
</ng-template>

<ng-template #templateInvalid>
  Template doesn't exist or you do not have access to it
  <a class="btn btn-primary" href="{{manager_url}}" role="button">Go to Manager</a>
</ng-template>