import { Component, Input } from '@angular/core';
import { TextSetting } from '../../../Template/Settings/ValueSettings/TextSetting';

@Component({
  selector: 'app-textsetting',
  templateUrl: './textsetting.component.html',
  styleUrls: ['./textsetting.component.scss']
})
export class TextSettingComponent {
  @Input() setting: TextSetting;

  getValue(): String {
    // get string value
    return this.setting.value as String;
  }

  setValue(value: string) {
    // set string value
    this.setting.setValue(value);
  }
}
