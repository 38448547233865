
import { FlowConnectionName } from "src/app/objects/Constants";
import { Time } from "src/app/objects/Time";
import { FlowConnectionData, FlowConnectionVariant, TimerFlowConnection } from "src/template/FlowConnectionData";
import { Setting } from "../Settings/Setting";
import { ValueDropdownSetting } from "../Settings/ValueSettings/Options/ValueDropdownSetting";
import { TimeSetting } from "../Settings/ValueSettings/TimeSetting";
import { FlowConnection } from "./FlowConnection";

/**
 * Flow connection for proceeding after amount of time.
 */
export class Timer extends FlowConnection {
    protected _name = FlowConnectionName.TIMER;

    private Timer: ValueDropdownSetting<string> = new ValueDropdownSetting('Mode', [
        { key: "Amount of time", value: "Amount of time" },
        { key: "Amount of time pp", value: "Amount of time pp" },
    ], 'Choose option', "Amount of time")
        .setValidConditionNonEmpty();
        
    private Time_in_seconds: TimeSetting = new TimeSetting('Time', new Time(0, 0, 0))
        .setValidConditionNonEmpty();

    getSettings(): Setting[] {
        return [
            this.Timer,
            this.Time_in_seconds,
        ];
    };

    toObject(): FlowConnectionData {
        return {
            source: "",
            target: "",
            variant: FlowConnectionVariant.Timer,
            duration: this.Time_in_seconds.value,
        }
    }
    fromObject(flowConnectionData: TimerFlowConnection) {
        this.Time_in_seconds.setValue(flowConnectionData.duration);
    }
}