import { BuildingBlockData } from 'src/template/BuildingBlockData';
import * as uuid from 'uuid';
import { HasSettings } from '../HasSettings';
import { InputSetting } from '../Settings/InputSetting';
import { Setting } from '../Settings/Setting';

export type BuildingBlockType = new (getBuildingBlockBefore: () => BuildingBlock[]) => BuildingBlock;

export abstract class BuildingBlock extends HasSettings {
    // unique id of the building block.
    private _id: string = "buildingblock-" + uuid.v4();

    override readonly advandedSettingsHeader: string = "Advanced Settings";

    // type of building block.
    protected abstract _type: string;

    // Name of the building block.
    private _blockName: string;

    // All settings of the building block.
    protected mainSettings: Setting[] = [];

    // All advanced settings of the building block.
    protected advancedSettings: Setting[] = [];

    // Function to get all building blocks before this building block.
    protected getBuildingBlocksBefore: () => BuildingBlock[];

    constructor(getBuildingBlocksBefore: () => BuildingBlock[]) {
        super();
        this.getBuildingBlocksBefore = getBuildingBlocksBefore;
    }

    /**
     * Updates all inputs of the building block unsetting them if building block is not avaivable anymore.
     */
    updateInputs() {
        // Update all inputs
        for (let setting of this.getAllSettings()) {
            if (setting instanceof InputSetting) {
                setting.updateBuildingBlock();
            }
        }
    }

    toObject(): BuildingBlockData {
        // create building block data object
        var buildingBlockData: BuildingBlockData = {} as BuildingBlockData;
        
        // set building block data
        buildingBlockData.name = this.blockName;
        buildingBlockData.flowConnectionsOut = [];
        buildingBlockData.settings = {};

        // set variant and settings of building block data
        this.writeSettings(buildingBlockData);

        // return building block data
        return buildingBlockData;
    }

    fromObject(buildingBlockObject: BuildingBlockData, id: string): void {
        // Set name and id
        this.blockName = buildingBlockObject.name;
        this.id = id;

        // read settings
        this.readSettings(buildingBlockObject)

    }

    abstract writeSettings(buildingBlockObject: BuildingBlockData): void;

    abstract readSettings(buildingBlockObject: BuildingBlockData): void;

    override getMainSettings(): Setting[] {
        return this.mainSettings;
    }

    override getAdvancedSettings(): Setting[] {
        return this.advancedSettings;
    }

    //angular getters
    get blockName(): string {
        return this._blockName;
    }

    get type(): string {
        return this._type;
    };

    get id(): string {
        return this._id;
    }

    set blockName(name: string) {
        this._blockName = name;
    }

    set id(id: string) {
        this._id = id;
    }
}