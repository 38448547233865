import { SettingValue, ValueSettingData } from 'src/template/SettingData';
import { Setting } from "../Setting";

/**
 * Abstract class for a setting containing a value.
 */
export abstract class ValueSetting<T extends SettingValue> extends Setting {
    protected _value: T | (() => T);
    protected _valueSet: boolean = false;

    constructor(label: string) {
        super(label);
    }

    /**
     * Returns the value of the setting.
     */
    get value(): T {
        if (typeof this._value === 'function') {
            return this._value();
        }
        return this._value;
    }
    
    /**
     * Returns true if the value of the setting is set.
     */
    get valueSet(): boolean {
        return this._valueSet;
    }

    /**
     * Sets the value of the setting to a value or a function returning a value.
     * @param value value or function returning a value.
     * @param overwriteFunction if false, cannot set non function value if setting gets value from function.
     * @returns itself
     */
    setValue(value: T | (() => T), overwriteFunction: boolean = false): this {
        // cannot set value when setting gets value from function and overwriteFunction is false
        if(!overwriteFunction && typeof this._value === 'function' && typeof value !== 'function') {
            return this;
        }

        // set value
        this._valueSet = true;
        this._value = value;

        this.refreshValid();

        return this;
    }

    /**
     * Writes the value of the setting to an object containing the setting data.
     * @returns Object containing the setting data
     */
    toObject(): ValueSettingData<T> {
        if (!this.valueSet) {
            throw new Error("Value not set");
        }
        return {
            exposed: this.exposed,
            value: this.value,
        }
    }

    override fromObject(valueSettingData: ValueSettingData<T>) {
        super.fromObject(valueSettingData);
        this.setValue(valueSettingData.value);
    }
    
}