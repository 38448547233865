import { BlockName } from 'src/app/objects/Constants';
import { BlockVariant, DiscussionBlockData } from 'src/template/BuildingBlockData';
import { Author, WhoCanAnswer } from 'src/template/SettingValues';
import { InputSetting } from '../Settings/InputSetting';
import { Setting } from '../Settings/Setting';
import { CheckboxSetting } from '../Settings/ValueSettings/CheckboxSetting';
import { ValueDropdownSetting } from '../Settings/ValueSettings/Options/ValueDropdownSetting';
import { TextSetting } from '../Settings/ValueSettings/TextSetting';
import { BuildingBlock } from './BuildingBlock';

/**
 * Discussion Building Block
 */
export class Discussion extends BuildingBlock {
  protected override _type: string = BlockName.DISCUSSION; // type of building block

  // Main Settings
  private DiscussionTitle: TextSetting = new TextSetting('Text for above the screen')
    .setValidConditionNonEmpty();
  private Input: InputSetting = new InputSetting("Input", this.getBuildingBlocksBefore);

  // Advanced Settings
  private Author: ValueDropdownSetting<Author> = new ValueDropdownSetting('Author', [
    { key: "View author", value: Author.View_author },
    { key: "Make anonymous", value: Author.Make_anonymous },
  ], 'Choose option', 'View author');

  private Card_to_display_at_the_top_of_the_screen: InputSetting = new InputSetting('Card to display at the top of the screen', this.getBuildingBlocksBefore);

  private Who_can_answer: ValueDropdownSetting<WhoCanAnswer> = new ValueDropdownSetting('Who can answer', [
    { key: "Everyone", value: WhoCanAnswer.Everyone },
    { key: "Facilitator", value: WhoCanAnswer.Facilitator },
  ], 'Choose option', 'Everyone');

  private Mark_duplicates: CheckboxSetting = new CheckboxSetting('Mark duplicates', false);

  private InstructionsDiscussion: TextSetting = new TextSetting('Instructions');

  protected override mainSettings: Setting[] = [
    this.DiscussionTitle,
    this.Input,
  ]

  protected override advancedSettings: Setting[] = [
    this.Author,
    this.Card_to_display_at_the_top_of_the_screen,
    this.Who_can_answer,
    this.Mark_duplicates,
    this.InstructionsDiscussion,
  ]

  override writeSettings(buildingBlockObject: DiscussionBlockData): void {
    // set variant to discussion
    buildingBlockObject.variant = BlockVariant.DISCUSSION;

    // set settings
    buildingBlockObject.settings = {
      title: this.DiscussionTitle.toObject(),
      input: this.Input.toObject(),
      author: this.Author.toObject(),
      cardToDisplay: this.Card_to_display_at_the_top_of_the_screen.toObject(),
      whoCanAnswer: this.Who_can_answer.toObject(),
      markDuplicates: this.Mark_duplicates.toObject(),
      instructions: this.InstructionsDiscussion.toObject(),
    };
  }
  
  override readSettings(buildingBlockObject: DiscussionBlockData): void {
    this.DiscussionTitle.fromObject(buildingBlockObject.settings.title);
    this.Input.fromObject(buildingBlockObject.settings.input);
    this.Author.fromObject(buildingBlockObject.settings.author);
    this.Card_to_display_at_the_top_of_the_screen.fromObject(buildingBlockObject.settings.cardToDisplay);
    this.Who_can_answer.fromObject(buildingBlockObject.settings.whoCanAnswer);
    this.Mark_duplicates.fromObject(buildingBlockObject.settings.markDuplicates);
    this.InstructionsDiscussion.fromObject(buildingBlockObject.settings.instructions);
  }
}
