import { BlockVariant, BuildingBlockData } from 'src/template/BuildingBlockData';
import { FlowConnectionData } from 'src/template/FlowConnectionData';
import { TemplateData } from 'src/template/TemplateData';
import { BuildingBlockContainer } from './BuildingBlockContainer';
import { BuildingBlock, BuildingBlockType } from './BuildingBlocks/BuildingBlock';
import { FlowConnectionContainer } from './FlowConnections/FlowConnectionContainer';

/**
 * Template containing building blocks and flow connections.
 */
export class Template {
    private _buildingBlockContainers: BuildingBlockContainer[] = [];
    private _buildingBlockRegistry: Map<BlockVariant, BuildingBlockType> = new Map<BlockVariant, BuildingBlockType>();

    get Count(): number {
        return this.buildingBlockContainers.length;
    }

    public get buildingBlockContainers(): BuildingBlockContainer[] {
        return this._buildingBlockContainers;
    }

    get buildingBlocks(): BuildingBlock[] {
        return this.buildingBlockContainers.map(buildingBlockController => buildingBlockController.buildingBlock);
    }

    /**
     * Adds a building block type to the template registry such that the template can recognize the type.
     * @param variant variant of block.
     * @param buildingBlockType class of building block.
     * @returns itself
     */
    addBuildingBlockType(variant: BlockVariant, buildingBlockType: BuildingBlockType) {
        this._buildingBlockRegistry.set(variant, buildingBlockType);
        return this;
    }

    /**
     * Adds multiple building block types to the template registry such that the template can recognize the type.
     * @param buildingBlockTypes list of variant and the class of building blocks.
     * @returns itself
     */
    addBuildingBlockTypes(buildingBlockTypes: [BlockVariant, BuildingBlockType][]) {
        buildingBlockTypes.forEach(buildingBlockType => 
            this.addBuildingBlockType(buildingBlockType[0], buildingBlockType[1]));
        return this;
    }

    /**
     * Adds a building block to the template.
     * @param buildingBlockType Type of the building block to add.
     * @returns The building block controller of the added building block.
     */
    add(buildingBlockType: BuildingBlockType): BuildingBlockContainer {
        // create empty building block controller of type.
        let buildingBlockController: BuildingBlockContainer = {
            buildingBlock: new buildingBlockType(() => this.getBuildingBlockBefore(buildingBlockController)),
            flowConnectionContainer: new FlowConnectionContainer(),
            buildingBlockMemory: new Map<string, BuildingBlock>(),
        };

        // add building block controller to list.
        this.buildingBlockContainers.push(buildingBlockController);

        // rename building block.
        this.renameBuildingBlock(buildingBlockController, buildingBlockController.buildingBlock.type);
        return buildingBlockController;
    }

    /**
     * Removes a building block from the template.
     * @remarks if building block is not in template, nothing happens.
     * @param buildingblockController Building block controller of building block to remove.
     */
    remove(buildingblockController: BuildingBlockContainer) {
        let index = this.buildingBlockContainers.indexOf(buildingblockController);

        // if building block controller is not in list, return.
        if (index == -1) return;

        // remove building block controller from list.
        this._buildingBlockContainers.splice(index, 1);
        this.updateInputs();
    }

    /**
     * Changes the building block type of a building block controller
     * @param buildingBlockController Building block controller to change the building block type of.
     * @param buildingBlockType Type of the building block to change to.
     */
    change(buildingBlockController: BuildingBlockContainer, buildingBlockType: BuildingBlockType) {
        let buildingBlock = new buildingBlockType(() => this.getBuildingBlockBefore(buildingBlockController));

        // if building block is in memory, get it from memory.
        if (buildingBlockController.buildingBlockMemory.has(buildingBlock.type)) {
            buildingBlock = buildingBlockController.buildingBlockMemory.get(buildingBlock.type) as BuildingBlock;
        }

        buildingBlockController.buildingBlock.refreshValid();

        // set the building block on the controller
        buildingBlockController.buildingBlockMemory.set(buildingBlockController.buildingBlock.type, buildingBlockController.buildingBlock);
        buildingBlockController.buildingBlock = buildingBlock;

        // rename building block.
        this.renameBuildingBlock(buildingBlockController, buildingBlockController.buildingBlock.type);

        this.updateInputs();
    }

    /**
     * Swaps two building blocks in this template
     * @param index1 - Index of the first building block.
     * @param index2 - Index of the second building block.
     */
    private swap(index1: number, index2: number) {
        // if index is out of range, return.
        if (index1 >= this.Count || index1 <= -1 ||
            index2 >= this.Count || index2 <= -1) return;

        // swap building blocks.
        let temp = this.buildingBlockContainers[index1];
        this._buildingBlockContainers[index1] = this._buildingBlockContainers[index2];
        this._buildingBlockContainers[index2] = temp;

        this.updateInputs();
    }

    /**
     * Moves building block in the list
     * @param buildingblockController - controller of building block
     * @param indexDiff - difference in index to move
     */
    moveBuildingBlockContainer(buildingblockController: BuildingBlockContainer, indexDiff: number) {
        // this is done by swapping the building block with the one at indexDiff from it.
        // this is probably not the intended behaviour, but it works for now,
        // since it's only called with indexDiff = 1 or -1.
        
        
        // get index of building block controller.
        let index = this._buildingBlockContainers.indexOf(buildingblockController);

        // if building block is not in list, return.
        if (index == -1) return;

        // swap building blocks.
        this.swap(index, index + indexDiff);
    }

    /**
     * Gets the building blocks before the given building block controller.
     * @param buildingBlockController given building block controller
     * @returns Array of building blocks before the given building block controller.
     * @throws Error if building block controller is not in template.
     */
    getBuildingBlockBefore(buildingBlockController: BuildingBlockContainer): BuildingBlock[] {
        let index = this._buildingBlockContainers.indexOf(buildingBlockController);

        // if building block is not in list, throw error.
        if (index == -1) 
            throw new Error("Building block controller is not in template.");

        // return building blocks before the given building block controller.
        return this._buildingBlockContainers.slice(0, index).map(buildingBlockController => buildingBlockController.buildingBlock);
    }

    /**
     * Renames the building block of the given controller.
     * @remarks If the name is already taken, a number is added to the name.
     * @param buildingBlockController given building block controller
     * @param name new name of the building block
     */
    renameBuildingBlock(buildingBlockController: BuildingBlockContainer, name: string) {
        buildingBlockController.buildingBlock.blockName = this.getAvailableName(name, buildingBlockController.buildingBlock);
    }

    /**
     * Gets an available name with given name
     * @remark ignoreBuildingBlock should be the building block for which the name is changed otherwise the name is taken by itself.
     * @param name given name
     * @param ignoreBuildingBlock building block to ignore
     * @returns name with added number if name is already taken
     */
    private getAvailableName(name: string, ignoreBuildingBlock?: BuildingBlock): string {
        let nametaken = false;

        // check if name is already taken ignoring ignoreBuildingBlock.
        for (let i = 0; i < this._buildingBlockContainers.length; i++) {
            if (this._buildingBlockContainers[i].buildingBlock.blockName == name
                && this._buildingBlockContainers[i].buildingBlock != ignoreBuildingBlock) {
                nametaken = true;
            }
        }

        if (nametaken) {
            let index = 2;

            // add number to name and increment until name is available.
            for (let attemp = 0; attemp < this._buildingBlockContainers.length; attemp++) {
                let newName = name + " " + index++;
                nametaken = false;

                // check if name is already taken ignoring ignoreBuildingBlock.
                for (let i = 0; i < this._buildingBlockContainers.length; i++) {
                    if (this._buildingBlockContainers[i].buildingBlock.blockName == newName
                        && this._buildingBlockContainers[i].buildingBlock != ignoreBuildingBlock) {
                        nametaken = true;
                    }
                }

                if (!nametaken) {
                    // available name found, break.
                    name = newName;
                    break;
                }
            }
        }
        return name;
    }

    /**
     * Updates all input settings unsetting them if they are not valid anymore.
     */
    public updateInputs() {
        for (let buildingBlockController of this._buildingBlockContainers) {
            buildingBlockController.buildingBlock.updateInputs();
        }
    }

    /**
     * Writes the value of the template to an object containing the template data.
     * @returns Object containing the template data
     */
    toObject(): TemplateData {
        let buildingBlocks: { [key: string]: BuildingBlockData } = {};
        let flowConnections: { [key: string]: FlowConnectionData } = {};

        // Loop through all building blocks
        for (let i = 0; i < this._buildingBlockContainers.length; i++) {
            // Create building block data object
            let buildingBlock = this._buildingBlockContainers[i].buildingBlock;
            let buildingBlockData = buildingBlock.toObject();

            // Create flow connection data object
            let flowConnectionId = this._buildingBlockContainers[i].flowConnectionContainer.id;
            let flowConnectionData = this._buildingBlockContainers[i].flowConnectionContainer.toObject();
            flowConnectionData.source = buildingBlock.id;

            if (i < this._buildingBlockContainers.length - 1) {
                // Set target of flow connection to the next building block
                flowConnectionData.target = this._buildingBlockContainers[i + 1].buildingBlock.id;
            } else {
                // Set target of flow connection to null if there is no next building block
                delete flowConnectionData.target;
            }

            // Add building block and flow connection data to template data
            buildingBlockData.flowConnectionsOut!.push(flowConnectionId);
            flowConnections[flowConnectionId] = flowConnectionData;
            buildingBlocks[buildingBlock.id] = buildingBlockData;
        }

        return {
            firstBuildingBlock: this.Count == 0 ? "" : this._buildingBlockContainers[0].buildingBlock.id,
            buildingBlocks: buildingBlocks,
            flowConnections: flowConnections,
        }
    }

    /**
     * Reads the value of the template from the given template data.
     * @param templateData - Object containing the template data
     * @throws Error if a building block type is not known in the template data.
     */
    fromObject(templateData: TemplateData) {
        let blockId = templateData.firstBuildingBlock;

        // template contains no building blocks
        if (blockId == "") return;

        let block = templateData.buildingBlocks![blockId];
        // Loop through all building blocks in template 
        while (true) {
            // create building block of right type
            let buildingBlockType = this._buildingBlockRegistry.get(block.variant);

            // if building block type is not found, throw error
            if(buildingBlockType == undefined) {
                throw new Error("Building block type not found: " + block.variant);
            }

            let buildingBlockController = this.add(buildingBlockType);

            // read building block data
            buildingBlockController.buildingBlock.fromObject(block, blockId);

            // get flow connection data 
            let flowConnectionId = block.flowConnectionsOut![0];
            let flowConnectionData = templateData.flowConnections![flowConnectionId];
            // read flow connection data
            buildingBlockController.flowConnectionContainer.fromObject(flowConnectionData, flowConnectionId);

            if (flowConnectionData.target != undefined) {
                // get next block
                blockId = flowConnectionData.target;
                block = templateData.buildingBlocks![blockId];
            } else {
                // end of template reached
                break;
            }
        }
    }

    /**
     * Checks if the template is valid.
     * @returns true if template is valid, false otherwise.
     */
    checkValid(): boolean {
        let valid: boolean = true;
        // check if all building blocks are valid
        for (let buildingBlockController of this._buildingBlockContainers) {
            if (!this.checkBuildingBlockContainerValid(buildingBlockController)) {
                valid = false;
            }
        }
        return valid;
    }

    /**
     * Checks if single building block controller is valid in template.
     * @returns true if building block controller is valid, false otherwise.
     */
    private checkBuildingBlockContainerValid(buldingBlockController: BuildingBlockContainer): boolean {
        // check if building block and flow connection are valid 
        let buildingBlockValid = buldingBlockController.buildingBlock.checkValid();
        let flowConnectionValid = buldingBlockController.flowConnectionContainer.checkValid();

        return buildingBlockValid && flowConnectionValid;
    }
}