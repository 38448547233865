
<div *ngIf="!loading else mainLoading" class="container d-flex flex-column" style="max-width: 500px">
    <div *ngIf="loggedIn else notLoggedIn" >
        <div *ngIf="templateId else templateNotGiven">
            <app-template [templateId]="templateId"></app-template>
        </div>
    </div>
</div>


<ng-template #mainLoading>
    <br><br><br>
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <br>
    </div>
    <p style="text-align: center;">Retrieving template data...</p>
</ng-template>

<ng-template #templateNotGiven>
    <br><br><br>
    It appears you entered the builder without choosing a meeting template. Please return to the manager and choose a tempplate to edit.
</ng-template>

<ng-template #notLoggedIn>
    <div style="height: 3em; width: 100%;"></div>

    <!-- You are not logged in
    <button class="btn btn-primary" (click)="login()">
        Login
    </button> -->
    
    <div class="card mx-auto">
        <div class="card-body">
            <h5 class="card-title">Login</h5>
            <p class="card-text">It looks like you are not logged in. Please authenticate yourself using a Google account to
                get access to the builder.</p>
            <button class="btn btn-primary" (click)="login()">Authenticate with Google</button>
        </div>
    </div>
</ng-template>