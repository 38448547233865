<div class="card-header d-inline-flex justify-content-between align-items-center">
    <app-setting [setting]="setting" [label]="false"></app-setting>

    <div class="d-inline-flex align-items-center">
      <button
        type="button"
        style="background: none; height: auto"
        class="btn-close"
        (click)="moveUp()"
      >
        <i class="fa-solid fa-arrow-up fa-lg"></i>
      </button>
      <button
        type="button"
        style="background: none; height: auto"
        class="btn-close"
        (click)="moveDown()"
      >
        <i class="fa-solid fa-arrow-down fa-lg"></i>
      </button>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="remove()"
      ></button>
    </div>
</div>
