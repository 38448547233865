import { Setting } from "./Settings/Setting";

/**
 * Abstract class for objects containing settings.
 * Settings are divided into main and advanced settings.
 */
export abstract class HasSettings {
    abstract readonly advandedSettingsHeader: string;

    abstract getMainSettings(): Setting[];
    abstract getAdvancedSettings(): Setting[];
    
    /**
     * @returns Array of all settings for the object.
     */
    getAllSettings(): Setting[] {
        return [
            ...this.getMainSettings(),
            ...this.getAdvancedSettings()
        ];
    };

    /**
     * @returns true if all main settings are valid, false otherwise.
     */
    get mainSettingsValid(): boolean {
        return this.getMainSettings().every(setting => setting.valid);
    }

    /**
     * @returns true if all advanced settings are valid, false otherwise.
     */
    get advancedSettingsValid(): boolean {
        return this.getAdvancedSettings().every(setting => setting.valid);
    }

    /**
     * @returns true if all settings are valid, false otherwise.
     */
    get valid(): boolean {
        return this.mainSettingsValid && this.advancedSettingsValid;
    }

    /**
     * Checks if all settings are valid.
     * @returns true if all settings are valid, false otherwise.
     */
    checkValid(): boolean {
        var valid: boolean = true;
        // check if all settings are valid.
        for (let setting of this.getAllSettings()) {
            valid = setting.checkValid() && valid;
        }
        return valid;
    }

    /**
     * refreshes all settings to be valid again.
     */
    refreshValid(): void {
        for (let setting of this.getAllSettings()) {
            setting.refreshValid();
        }
    }
}