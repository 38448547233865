import { ValueSetting } from './ValueSetting';

/**
 * Setting containing a text value.
 */
export class TextSetting extends ValueSetting<string> {
    constructor(label: string, value: string = "") {
        super(label);
        this.setValue(value);
    }

    setValidConditionNonEmpty(): this {
        // text has to be non-empty, including no multiple white spaces.
        return this.setValidCondition(() => this.value.match(/^ *$/) == null);
    }
}
