import { Component, Input } from '@angular/core';
import { SettingDropdownSetting } from 'src/app/Template/Settings/ValueSettings/Options/SettingDropdownSetting';
import { SettingValue } from 'src/template/SettingData';

@Component({
  selector: 'app-settingdropdownsetting',
  templateUrl: './settingdropdownsetting.component.html',
  styleUrls: ['./settingdropdownsetting.component.scss']
})
export class SettingDropdownSettingComponent {
  @Input() setting: SettingDropdownSetting<SettingValue>;

}
