import { FilterData } from 'src/template/FilterData';
import { ValueSettingData } from 'src/template/SettingData';
import { Filter } from '../../Cards/Filters/Filter';
import { ValueSetting } from './ValueSetting';
import { canReadFromValue } from './canReadFromValue';

/**
 * Setting containing a filter for an input.
 */
export class FilterSetting extends ValueSetting<FilterData> implements canReadFromValue<FilterData> {
    private _filter: Filter;

    constructor(label: string, filter: Filter) {
        super(label);
        this._filter = filter;
        this.setValue(() => filter.toObject());
    }

    get filter(): Filter {
        return this._filter;
    }

    override fromObject(valueSettingData: ValueSettingData<FilterData>) {
        super.fromObject(valueSettingData);
        
        // read filter from FilterData
        this._filter.fromObject(valueSettingData.value);
    }

    isValueValid(value: FilterData): boolean {
        // check if variant is the matches.
        return value.variant == this._filter.variant;
    }
}