import { Component, Input } from '@angular/core';
import { VcFlowService } from '@gametailors/v-cilitator-angular';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'builder';

  @Input() environment: any = environment;

  constructor(
    private vcFlow: VcFlowService
  ) {

  }

  ngOnInit() {
    // init vcFlow
    this.vcFlow.init((state) => {
      console.log(state)
    });
  }
}
