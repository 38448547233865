import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Discussion } from 'src/app/Template/BuildingBlocks/Discussion';
import { Pause } from 'src/app/Template/BuildingBlocks/Pause';
import { SectionDefiner } from 'src/app/Template/BuildingBlocks/SectionDefiner';
import { Voting } from 'src/app/Template/BuildingBlocks/Voting';
import { BlockName } from 'src/app/objects/Constants';
import { BuildingBlock, BuildingBlockType } from '../../Template/BuildingBlocks/BuildingBlock';
import { IndividualAnswers } from '../../Template/BuildingBlocks/IndividualAnswers';
import { Start } from '../../Template/BuildingBlocks/Start';

@Component({
  selector: 'app-blockmenu',
  templateUrl: './blockmenu.component.html',
  styleUrls: ['./blockmenu.component.scss']
})
export class BlockMenuComponent {
  // list of all building blocks for the menu
  buildingBlocks: { name: BlockName, type: BuildingBlockType }[] = [
    { name: BlockName.START, type: Start },
    { name: BlockName.INDIVIDUAL_ANSWERS, type: IndividualAnswers },
    { name: BlockName.VOTING, type: Voting },
    { name: BlockName.DISCUSSION, type: Discussion },
    { name: BlockName.SECTION_DEFINER, type: SectionDefiner },
    { name: BlockName.PAUSE, type: Pause }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { filterBuildingBlocks: BuildingBlock[] }, private dialogRef: MatDialogRef<BlockMenuComponent>) {
    // filter building blocks in filterBuildingBlocks 
    // such that they are not shown in the menu.
    this.buildingBlocks = this.buildingBlocks.filter(
      buildingBlock => data.filterBuildingBlocks.some(
        filterBuildingBlock => filterBuildingBlock.type != buildingBlock.name)
    );
  }

  choice(buildingBlockType: BuildingBlockType) {
    // close dialog with building block type as result
    this.dialogRef.close({ buildingBlockType: buildingBlockType });
  }
}
