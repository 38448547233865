import { ValueSetting } from './ValueSetting';

/**
 * Setting containing a number value.
 */
export class NumberSetting extends ValueSetting<number> {
    private min: number | undefined
    private max: number | undefined


    constructor(label: string, value: number) {
        super(label);
        this.setValue(value);
    }

    setValidConditionNonEmpty(): this {
        // number has to be non-zero.
        return this.setValidCondition(() => this.value != 0);
    }

    /**
     * Sets a minumum bound and maximum bound on the minimum and maximum value allowed 
     * @param min - Minimum value allowed
     * @param max - Maximum value allowed
     * @returns itself.
     */
    setBounds(min: number, max: number): this {
        this.min = min
        this.max = max
        return this
    }

    /**
     * Sets a minumum bound on the minimum value allowed
     * @param min - Minimum value allowed
     * @returns itself.
     */
    setMinBound(min: number): this {
        this.min = min
        return this
    }

    /**
     * Sets a maximum bound on the maximum value allowed
     * @param max - Maximum value allowed
     * @returns itself.
     */
    setMaxBound(max: number): this {
        this.max = max
        return this
    }

    // override get value(): number {
    //     return super.value
    // }
    
    override setValue(value: number | (() => number)) : this {
        // check if value is a function.
        if (!(typeof this._value === 'function')) {
            this._valid = true;
            this._valueSet = true;
            // if min or max is set, check if value is within bounds
            // and set value to min or max if it is not
            if (this.min !== undefined) {
                if ((value as number) < this.min) {
                    value = this.min
                }
            }
            if (this.max !== undefined) {
                if ((value as number) > this.max) {
                    value = this.max
                }
            }
            super.setValue(value);
        } 
        //TODO: Handle if value is function ( should probably wrap a clamp function with bounds around function if there are any bounds)
        return this;
    }
}