
import { InputSettingData } from 'src/template/SettingData';
import { BuildingBlock } from '../BuildingBlocks/BuildingBlock';
import { BlockInput } from '../Cards/BlockInput';
import { Setting } from './Setting';


/**
 * Setting containing a input.
 */
export class InputSetting extends Setting {
    private _input: BlockInput;

    constructor(label: string, getBuildingBlocksBefore: () => BuildingBlock[]) {
        super(label);
        this._input = new BlockInput(getBuildingBlocksBefore);
    }

    setValidConditionNonEmpty(): this {
        // building block has to be set.
        return this.setValidCondition(() => this.input.buildingBlockSet);
    }

    /**
     * Updates the building block of the input, by unsetting it if it is not available anymore.
     */
    updateBuildingBlock() {
        this._input.updateBuildingBlock();
    }

    /**
     * Writes the value of the setting to an object containing the setting data.
     * @returns Object containing the setting data
     */
    toObject(): InputSettingData<null> {
        return {
            exposed: this.exposed,
            value: null,
            input: this.input.toObject(),
        }
    }

    override fromObject(inputSettingData: InputSettingData<null>): void {
        super.fromObject(inputSettingData);
        this.input.fromObject(inputSettingData.input);
    }

    public get input(): BlockInput {
        return this._input;
    }
}
