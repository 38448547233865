<div class="list-group list-group-flush">
    <app-multisettingitem
        *ngFor="let setting of setting.settings let i = index"
        class="list-group-item px-4"
        [setting]="setting"
        [index]="i"
        (action)="action($event)"
    ></app-multisettingitem>
</div>
<button class="btn btn-secondary" (click)="addSetting()">+</button>
