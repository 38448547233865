<div class="card m-2">
  <div
    class="card-header d-inline-flex justify-content-between align-items-center"
  >
    <div class="d-inline-flex align-items-center">
      <h3
        [ngClass]="{
          'card-title': true,
          'text-danger': !(
            buildingBlockController.buildingBlock.valid &&
            buildingBlockController.flowConnectionContainer.valid
          )
        }"
        style="margin: 0"
      >
        {{ buildingBlock.blockName }}
      </h3>
      <button
        type="button"
        style="background: none; height: auto"
        class="btn-close"
        (click)="change()"
      >
        <i class="fa-solid fa-caret-down"></i>
      </button>
    </div>

    <div class="d-inline-flex align-items-center">
      <button
        type="button"
        style="background: none; height: auto"
        class="btn-close"
        (click)="moveUp()"
      >
        <i class="fa-solid fa-arrow-up fa-lg"></i>
      </button>
      <button
        type="button"
        style="background: none; height: auto"
        class="btn-close"
        (click)="moveDown()"
      >
        <i class="fa-solid fa-arrow-down fa-lg"></i>
      </button>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="remove()"
      ></button>
    </div>
  </div>
  <!-- most important settings -->
  <div class="list-group list-group-flush">
    <app-setting
      class="list-group-item px-4"
      *ngFor="let setting of buildingBlock.getMainSettings()"
      [setting]="setting"
      (OpenSettings)="openSettings($event)"
    ></app-setting>
  </div>
  <!-- advanced settings -->
  <div
    class="card-body"
    *ngIf="buildingBlock.getAdvancedSettings().length != 0"
  >
    <button
      [ngClass]="{
        btn: true,
        'btn-primary': true,
        'text-danger': !buildingBlock.advancedSettingsValid
      }"
      (click)="openAdvancedSettings()"
    >
      Advanced Settings
    </button>
  </div>
  <!-- flow connection -->
  <div class="card-body">
    <button
      [ngClass]="{
        btn: true,
        'btn-primary': true,
        'text-danger': !buildingBlockController.flowConnectionContainer.valid
      }"
      (click)="openFlowConnectionMenu()"
    >
      Flow Connection
    </button>
  </div>
</div>
