import { Time } from 'src/app/objects/Time';
import { ValueSetting } from './ValueSetting';

/**
 * Setting containing a time value.
 */
export class TimeSetting extends ValueSetting<number> {
    private _duration: Time;

    constructor(label: string, value: Time) {
        super(label);
        this.duration = value;
    }

    setValidConditionNonEmpty(): this {
        // time has to be larger than 0.
        return this.setValidCondition(() => this.duration.toMilliseconds() > 0);
    }

    get duration(): Time {
        return this._duration;
    }

    set duration(time: Time) {
        this._duration = time;

        // makes sure value and duration represent same duration.
        super.setValue(time.toMilliseconds());
    }

    override setValue(value: number): this {
        super.setValue(value);

        // makes sure value and duration represent same duration.
        this._duration = Time.fromMilliseconds(value);
        return this;
    }
}