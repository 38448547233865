import { BlockName, MAX_PARTICIPANTS } from 'src/app/objects/Constants';
import { BlockVariant, StartBlockData } from 'src/template/BuildingBlockData';
import { Setting } from '../Settings/Setting';
import { NumberSetting } from '../Settings/ValueSettings/NumberSetting';
import { TextSetting } from '../Settings/ValueSettings/TextSetting';
import { BuildingBlock } from './BuildingBlock';

export class Start extends BuildingBlock {
  protected override _type: string = BlockName.START; // type of building block

  // Main Settings
  private name: TextSetting = new TextSetting('Name')
    .setValidConditionNonEmpty();

  private category: TextSetting = new TextSetting('Category')

  private description: TextSetting = new TextSetting('Description')
    .setValidConditionNonEmpty();

  private meetinPurpose: TextSetting = new TextSetting('Meeting purpose')

  private expectation: TextSetting = new TextSetting('What do you expect to achieve')



  // Advanced Settings
  private Participants: NumberSetting = new NumberSetting('Participants', 3)
    .setBounds(0, MAX_PARTICIPANTS);

  protected override mainSettings: Setting[] = [
    this.name,
    this.category,
    this.description,
    this.meetinPurpose,
    this.expectation,
  ]

  protected override advancedSettings: Setting[] = [
    this.Participants,
  ]

  override writeSettings(buildingBlockObject: StartBlockData) {
    // set variant to start
    buildingBlockObject.variant = BlockVariant.START;

    // set settings
    buildingBlockObject.settings = {
      name: this.name.toObject(),
      category: this.category.toObject(),
      description: this.description.toObject(),
      meetingPurpose: this.meetinPurpose.toObject(),
      expectation: this.expectation.toObject(),
      participants: this.Participants.toObject(),
    };

  }

  override readSettings(buildingBlockObject: StartBlockData): void {
    this.name.fromObject(buildingBlockObject.settings.name);
    this.category.fromObject(buildingBlockObject.settings.category);
    this.description.fromObject(buildingBlockObject.settings.description);
    this.meetinPurpose.fromObject(buildingBlockObject.settings.meetingPurpose);
    this.expectation.fromObject(buildingBlockObject.settings.expectation);
    this.Participants.fromObject(buildingBlockObject.settings.participants);
  }

}
