import { BlockName } from 'src/app/objects/Constants';
import { BlockVariant, SectionDefinerBlockData } from 'src/template/BuildingBlockData';
import { InputSetting } from '../Settings/InputSetting';
import { Setting } from '../Settings/Setting';
import { TextSetting } from '../Settings/ValueSettings/TextSetting';
import { BuildingBlock } from './BuildingBlock';

/**
 * Section definer Building Block
 */
export class SectionDefiner extends BuildingBlock {
  protected override _type: string = BlockName.SECTION_DEFINER; // type of building block

  // Main Settings
  private Name: TextSetting = new TextSetting('Name')
    .setValidConditionNonEmpty();

  private Input: InputSetting = new InputSetting("Input", this.getBuildingBlocksBefore);

  protected override mainSettings: Setting[] = [
    this.Name,
    this.Input
  ]

  protected override advancedSettings: Setting[] = [
  ]

  override writeSettings(buildingBlockObject: SectionDefinerBlockData): void {
    // set variant to section definer
    buildingBlockObject.variant = BlockVariant.SECTION_DEFINER;
    
    // set settings
    buildingBlockObject.settings.name = this.Name.toObject();
    buildingBlockObject.settings.input = this.Input.toObject();
  }

  override readSettings(buildingBlockObject: SectionDefinerBlockData): void {
    this.Name.fromObject(buildingBlockObject.settings.name);
    this.Input.fromObject(buildingBlockObject.settings.input);
  }

}
