import { SettingValue, ValueSettingData } from 'src/template/SettingData';
import { canReadFromValue } from '../canReadFromValue';
import { DropdownSetting, option } from './DropdownSetting';


/**
 * Dropdown with Value Settings as options.
 * @remark Value Settings must implement canReadFromValue interface
 * in order to know what setting should be used when reading a value.
 */
export class SettingDropdownSetting<T extends SettingValue> extends DropdownSetting<T> {
    // is either an array of settings or a function that returns an array of settings
    private _settingOptions: { key: string, value: canReadFromValue<T> }[] | (() => { key: string, value: canReadFromValue<T> }[]);

    constructor(label: string, options: { key: string, value: canReadFromValue<T> }[] | (() => { key: string, value: canReadFromValue<T> }[]), placeholder: string = '') {
        let dropdownOption: option<T>[] | (() => option<T>[]);
        if (typeof options == 'function') {
            // if options is a function, set dropdownOption to a function returning an array of options
            dropdownOption = () => options().map(option => {
                return {
                    key: option.key,
                    value: (() => option.value.value) // set value to a function returning value of setting
                }
            });
        } else {
            // if options is an array, set dropdownOption to an array of options
            dropdownOption = options.map(option => {
                return {
                    key: option.key,
                    value: (() => option.value.value) // set value to a function returning value of setting
                }
            });
        }
        super(label, dropdownOption, placeholder);

        this._settingOptions = options;
    }

    get settingOption(): canReadFromValue<T> | undefined {
        // if value is set, return the settingOption that matches the key
        if (this._valueSet) {
            return this.settingOptions.find(option => option.key == this.selectedOption.key)?.value
        }
        return undefined
    }

    private get settingOptions(): { key: string, value: canReadFromValue<T> }[] {
        // if _settingOptions is a function, call it and return the result
        if (typeof this._settingOptions === 'function') {
            return this._settingOptions()
        } else {
            return this._settingOptions
        }
    }

    override fromObject(valueSettingData: ValueSettingData<T>) {
        super.fromObject(valueSettingData)

        // Find the option that matches the value.
        var settingOption = this.settingOptions.find(option => option.value.isValueValid(valueSettingData.value))

        if (settingOption == undefined) {
            // option not found. throw error.
            throw new Error("Option not found: " + valueSettingData.value)
        } else {
            // select option.
            this.selectWithKey(settingOption.key)
            this.settingOption!.fromObject(valueSettingData)
        }
    }


}