import { FlowConnectionName } from "src/app/objects/Constants";
import { FlowConnectionData, FlowConnectionVariant } from "src/template/FlowConnectionData";
import * as uuid from 'uuid';
import { HasSettings } from "../HasSettings";
import { Setting } from "../Settings/Setting";
import { ValueDropdownSetting } from "../Settings/ValueSettings/Options/ValueDropdownSetting";
import { FacilitatorApproves } from "./FacilitatorApproves";
import { FlowConnection } from "./FlowConnection";
import { Timer } from "./Timer";

/**
 * Controller for a flow connection between building blocks.
 * Contains settings for choosing the amount and type of flow connections.
 */
export class FlowConnectionContainer extends HasSettings {
    private _id: string = "flowconnection-" + uuid.v4(); // unique id of the flow connection

    override readonly advandedSettingsHeader: string = "Flow Connection";

    // all possible flow connections
    private flowConnections: { [key: string]: FlowConnection } = {
        [FlowConnectionName.FACILITATOR_APPROVES]: new FacilitatorApproves(),
        [FlowConnectionName.TIMER]: new Timer(),
    }

    // dropdown for choosing flow connection
    private flowConnectionSetting: ValueDropdownSetting<null> = new ValueDropdownSetting<null>("Flow Connection",
        [{ key: FlowConnectionName.FACILITATOR_APPROVES, value: null },
        { key: FlowConnectionName.TIMER, value: null }], "", FlowConnectionName.FACILITATOR_APPROVES);

    constructor() {
        super();
    }

    get id(): string {
        return this._id;
    }

    override getMainSettings(): [] {
        return [];
    }

    override getAdvancedSettings(): Setting[] {
        return [
            // flow connection dropdown
            this.flowConnectionSetting,
            // settings for the selected flow connection
            ...this.flowConnections[this.flowConnectionSetting.selectedOption.key].getSettings(),
        ];
    }

    toObject(): FlowConnectionData {
        return this.flowConnections[this.flowConnectionSetting.selectedOption.key].toObject();
    }

    fromObject(flowConnectionData: FlowConnectionData, id: string) {
        this._id = id;

        // select the flow connection with the given type.
        this.chooseFlowConnection(flowConnectionData.variant);

        // read data into selected flow connection.
        this.flowConnections[this.flowConnectionSetting.selectedOption.key].fromObject(flowConnectionData);
    }

    /**
     * Selects the flow connection with the given type.
     * @param type given type of flow connection.
     */
    private chooseFlowConnection(type: FlowConnectionVariant) {
        switch (type) {
            case FlowConnectionVariant.FacilitatorApproves:
                this.flowConnectionSetting.selectWithKey(FlowConnectionName.FACILITATOR_APPROVES);
                break;
            case FlowConnectionVariant.Timer:
                this.flowConnectionSetting.selectWithKey(FlowConnectionName.TIMER);
                break;
            default:
                throw new Error('Invalid flow connection type');
        }
    }
}