import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BuildingBlockContainer } from '../../Template/BuildingBlockContainer';
import { BuildingBlock, BuildingBlockType } from '../../Template/BuildingBlocks/BuildingBlock';
import { AdvancedSettingsMenuComponent } from '../advancedsettingsmenu/advancedsettingsmenu.component';

import { HasSettings } from 'src/app/Template/HasSettings';
import { Template } from 'src/app/Template/Template';
import { BlockMenuComponent } from '../blockmenu/blockmenu.component';

@Component({
  selector: 'app-buildingblock',
  templateUrl: './buildingblock.component.html',
  styleUrls: ['./buildingblock.component.scss'],
})
export class BuildingBlockComponent {
  @Input() buildingBlockController: BuildingBlockContainer;
  @Input() template: Template;
  @Input() index: number;

  get buildingBlock(): BuildingBlock {
    return this.buildingBlockController.buildingBlock;
  }

  constructor(private dialog: MatDialog) { }

  openSettings(action: { settings: HasSettings }) {
    // open advanced settings menu with settings
    this.dialog.open(AdvancedSettingsMenuComponent, {
      data: { advancedSettings: action.settings },
    });
  }

  openAdvancedSettings() {
    // open advanced settings menu with building block
    this.openSettings({ settings: this.buildingBlockController.buildingBlock })
  }

  openFlowConnectionMenu() {
    // open advanced settings menu with flow connection container
    this.openSettings({ settings: this.buildingBlockController.flowConnectionContainer })
  }

  openBlockMenu(filterBuildingBlocks: BuildingBlock[]) {
    // open block menu without current building block in it.
    // change building block to selected building block when closed.
    this.dialog.open(BlockMenuComponent, {
      data: { filterBuildingBlocks: filterBuildingBlocks },
    }).afterClosed().subscribe((result) => {
      this.changeBuildingBlock(result.buildingBlockType);
    });
  }

  changeBuildingBlock(buildingBlockType: BuildingBlockType) {
    // check if building block was chosen otherwise do nothing
    if (buildingBlockType == null) return;

    // change building block to selected building block
    this.template.change(this.buildingBlockController, buildingBlockType);
  }

  moveUp() {
    // move building block by -1 in the template
    this.template.moveBuildingBlockContainer(this.buildingBlockController, -1);
  }

  moveDown() {
    // move building block by +1 in the template
    this.template.moveBuildingBlockContainer(this.buildingBlockController, 1);
  }

  change() {
    // open block menu
    this.openBlockMenu([this.buildingBlockController.buildingBlock]);
  }

  remove() {
    // remove building block from template
    this.template.remove(this.buildingBlockController);
  }
}

