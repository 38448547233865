import { BlockVariant, VotingBlockData } from 'src/template/BuildingBlockData';
import { Author, GroupCards, MaxNumberOfVotesPerAnswerpp, MaxNumberOfVotesPerAuthorpp } from 'src/template/SettingValues';
import { BlockName, MAX_VOTING } from '../../objects/Constants';
import { InputSetting } from '../Settings/InputSetting';
import { Setting } from '../Settings/Setting';
import { CheckboxSetting } from '../Settings/ValueSettings/CheckboxSetting';
import { NumberSetting } from '../Settings/ValueSettings/NumberSetting';
import { ValueDropdownSetting } from '../Settings/ValueSettings/Options/ValueDropdownSetting';
import { TextSetting } from '../Settings/ValueSettings/TextSetting';
import { BuildingBlock } from './BuildingBlock';

/**
  * Voting Building Block
  */
export class Voting extends BuildingBlock {
  protected override _type: string = BlockName.VOTING; // type of building block

  // Main Settings
  private Text_for_above_the_screen: TextSetting = new TextSetting('Text for above the screen')
    .setValidConditionNonEmpty();

  private Input: InputSetting = new InputSetting("Input", this.getBuildingBlocksBefore)
    .setValidConditionNonEmpty();

  // Advanced Settings
  private Max_number_of_votes_per_answer_pp: ValueDropdownSetting<MaxNumberOfVotesPerAnswerpp> = new ValueDropdownSetting('Max number of votes per answer pp', [
    { key: "Unlimited", value: MaxNumberOfVotesPerAnswerpp.Unlimited },
    { key: "Number of votes", value: MaxNumberOfVotesPerAnswerpp.Amount_pp },
  ], 'Choose option', "Unlimited");

  private Amount_of_votes_per_answer_pp: NumberSetting = new NumberSetting('Amount of votes', 1)
    .setVisibleCondition([[this.Max_number_of_votes_per_answer_pp, (setting) => setting.value != MaxNumberOfVotesPerAnswerpp.Unlimited]])
    .setBounds(0, MAX_VOTING);

  private Number_of_votes_pp: NumberSetting = new NumberSetting('Number of votes pp', 1)
    .setBounds(0, MAX_VOTING);

  private Can_vote_on_own_card: CheckboxSetting = new CheckboxSetting('Can vote on own card', true);

  private Group_cards: ValueDropdownSetting<GroupCards> = new ValueDropdownSetting('Group cards', [
    { key: "No grouping", value: GroupCards.No_Grouping },
    { key: "Group by author", value: GroupCards.PerAuthor },
  ], 'Choose option', "No grouping"); // Not used currently

  private Max_number_of_votes_per_author: ValueDropdownSetting<MaxNumberOfVotesPerAuthorpp> = new ValueDropdownSetting('Max number of votes per author', [
    { key: "Unlimited", value: MaxNumberOfVotesPerAuthorpp.Unlimited },
    { key: "Number of votes", value: MaxNumberOfVotesPerAuthorpp.Amount_pp },
  ], 'Choose option', "Unlimited");

  private Amount_of_votes_per_author: NumberSetting = new NumberSetting('Amount of votes', 1)
    .setVisibleCondition([[this.Max_number_of_votes_per_author, (setting) => setting.value != MaxNumberOfVotesPerAuthorpp.Unlimited]])
    .setBounds(0, MAX_VOTING);

  private Joint_voting: CheckboxSetting = new CheckboxSetting('Joint voting', false); // Not used currently

  private Author: ValueDropdownSetting<Author> = new ValueDropdownSetting('Author', [
    { key: "View author", value: Author.View_author },
    { key: "Make anonymous", value: Author.Make_anonymous },
  ], 'Choose option', "View author");

  private Mark_duplicates: CheckboxSetting = new CheckboxSetting('Mark duplicates', false);

  private InstructionsVoting: TextSetting = new TextSetting('Instructions');



  protected override mainSettings: Setting[] = [
    this.Text_for_above_the_screen,
    this.Input,
  ]

  protected override advancedSettings: Setting[] = [
    this.Max_number_of_votes_per_answer_pp,
    this.Amount_of_votes_per_answer_pp,
    this.Number_of_votes_pp,
    this.Can_vote_on_own_card,
    //this.Group_cards,
    this.Max_number_of_votes_per_author,
    this.Amount_of_votes_per_author,
    //this.Joint_voting,
    this.Author,
    this.Mark_duplicates,
    this.InstructionsVoting,
  ]

  override writeSettings(buildingBlockObject: VotingBlockData): void {
    // set variant to voting
    buildingBlockObject.variant = BlockVariant.VOTING;

    // set settings
    buildingBlockObject.settings = {
      title: this.Text_for_above_the_screen.toObject(),
      input: this.Input.toObject(),
      author: this.Author.toObject(),
      voteLimit: this.Number_of_votes_pp.toObject(),
      canVoteOnOwnCards: this.Can_vote_on_own_card.toObject(),
      maxNrOfVotesPerAuthorppOption: this.Max_number_of_votes_per_author.toObject(),
      maxNrOfVotesPerAuthorpp: this.Amount_of_votes_per_author.toObject(),
      maxNrOfVotesPerCardppOption: this.Max_number_of_votes_per_answer_pp.toObject(),
      maxNrOfVotesPerCardpp: this.Amount_of_votes_per_answer_pp.toObject(),
      groupCards: this.Group_cards.toObject(),
      jointVoting: this.Joint_voting.toObject(),
      markDuplicates: this.Mark_duplicates.toObject(),
      instructions: this.InstructionsVoting.toObject(),
    };
  }

  override readSettings(buildingBlockObject: VotingBlockData): void {
    this.Text_for_above_the_screen.fromObject(buildingBlockObject.settings.title);
    this.Input.fromObject(buildingBlockObject.settings.input);
    this.Author.fromObject(buildingBlockObject.settings.author);
    this.Number_of_votes_pp.fromObject(buildingBlockObject.settings.voteLimit);
    this.Can_vote_on_own_card.fromObject(buildingBlockObject.settings.canVoteOnOwnCards);
    this.Max_number_of_votes_per_author.fromObject(buildingBlockObject.settings.maxNrOfVotesPerAuthorppOption);
    this.Amount_of_votes_per_author.fromObject(buildingBlockObject.settings.maxNrOfVotesPerAuthorpp);
    this.Max_number_of_votes_per_answer_pp.fromObject(buildingBlockObject.settings.maxNrOfVotesPerCardppOption);
    this.Amount_of_votes_per_answer_pp.fromObject(buildingBlockObject.settings.maxNrOfVotesPerCardpp);
    this.Group_cards.fromObject(buildingBlockObject.settings.groupCards);
    this.Joint_voting.fromObject(buildingBlockObject.settings.jointVoting);
    this.Mark_duplicates.fromObject(buildingBlockObject.settings.markDuplicates);
    this.InstructionsVoting.fromObject(buildingBlockObject.settings.instructions);
  }

}
