import { Component, Input } from '@angular/core';
import { TimeSetting } from '../../../Template/Settings/ValueSettings/TimeSetting';
import { Time } from '../../../objects/Time';

@Component({
  selector: 'app-timesetting',
  templateUrl: './timesetting.component.html',
  styleUrls: ['./timesetting.component.scss']
})
export class TimeSettingComponent {
  @Input() setting: TimeSetting;

  getTime(): String {
    // get string value in format hh:mm:ss from time
    return (this.setting.duration as Time).toString();
  }

  setTime(value: String) {
    // time string format: hh:mm:ss to Time object
    let temparray: string[] = value.split(':');
    this.setting.duration = (new Time(parseInt(temparray[0]), parseInt(temparray[1]), parseInt(temparray[2])));
  }
}



