import { BlockName } from 'src/app/objects/Constants';
import { BlockVariant, PauseBlockData } from 'src/template/BuildingBlockData';
import { Setting } from '../Settings/Setting';
import { BuildingBlock } from './BuildingBlock';

/**
 * Blank Building Block
 */
export class Pause extends BuildingBlock {
  protected override _type: string = BlockName.PAUSE; // type of building block


  protected override mainSettings: Setting[] = [
  ]

  protected override advancedSettings: Setting[] = [
  ]

  override writeSettings(buildingBlockObject: PauseBlockData) {
    // set variant to pause
    buildingBlockObject.variant = BlockVariant.PAUSE;

    return buildingBlockObject;
  }

  override readSettings(buildingBlockObject: PauseBlockData): void {
  }

}
