<div *ngIf="setting.visible"  class="setting">
  <div *ngIf="label"
    [ngClass]="{
      'form-label': true,
      'text-danger': !setting.valid 
    }"
  >
    {{ setting.label }}
  </div>
  <div [ngSwitch]="setting.constructor.name" class="field">
    <app-textsetting
      *ngSwitchCase="'TextSetting'"
      [setting]="getTextSetting()"
    ></app-textsetting>
    <app-timesetting
      *ngSwitchCase="'TimeSetting'"
      [setting]="getTimeSetting()"
    ></app-timesetting>
    <app-valuedropdownsetting
      *ngSwitchCase="'ValueDropdownSetting'"
      [setting]="getDropdownSetting()"
    ></app-valuedropdownsetting>
    <app-multisetting
      *ngSwitchCase="'MultiSetting'"
      [setting]="getMultiSetting()"
    ></app-multisetting>
    <app-numbersetting
      *ngSwitchCase="'NumberSetting'"
      [setting]="getNumberSetting()"
    ></app-numbersetting>
    <app-inputsetting
      *ngSwitchCase="'InputSetting'"
      [setting]="getInputSetting()"
      (OpenSettingsChild)="OpenSettings.emit($event)"
    ></app-inputsetting>
    <app-filtersetting
      *ngSwitchCase="'FilterSetting'"
      [setting]="getFilterSetting()"
    ></app-filtersetting>
    <app-checkboxsetting
      *ngSwitchCase="'CheckboxSetting'"
      [setting]="getCheckboxSetting()"
    ></app-checkboxsetting>
    <app-settingdropdownsetting
      *ngSwitchCase="'SettingDropdownSetting'"
      [setting]="getOptionSetting()"
    ></app-settingdropdownsetting>
  </div>
</div>
