import { Component, Input } from '@angular/core';
import { MultiSetting } from '../../../../Template/Settings/ValueSettings/MultiSetting';
import { MultiSettingAction } from '../multisettingitem/multisettingitem.component';

@Component({
  selector: 'app-multisetting',
  templateUrl: './multisetting.component.html',
  styleUrls: ['./multisetting.component.scss']
})
export class MultiSettingComponent {
  @Input() setting: MultiSetting<any>;

  addSetting() {
    this.setting.add();
  }

  action(action: { action: MultiSettingAction; index: number }) {
    // perform action on setting at index
    switch (action.action) {
      case MultiSettingAction.MoveUp:
        // swap setting with setting above it
        this.setting.swap(action.index - 1, action.index);
        break;
      case MultiSettingAction.MoveDown:
        // swap setting with setting below it
        this.setting.swap(action.index, action.index + 1);
        break;
      case MultiSettingAction.Remove:
        // remove setting
        this.setting.remove(action.index);
        break;
    }
  }

}
