import { SettingValue, ValueSettingData } from 'src/template/SettingData';
import { DropdownSetting } from './DropdownSetting';


/**
 * Setting containing a value which is chosen from a dropdown menu.
 */
export class ValueDropdownSetting<T extends SettingValue> extends DropdownSetting<T> {
    constructor(label: string, options: { key: string, value: T }[] | (() => { key: string, value: T }[]),
        placeholder: string = '', selectedOption: string | undefined = undefined) {
        super(label, options, placeholder, selectedOption);
    }

    override fromObject(valueSettingData: ValueSettingData<T>) {
        super.fromObject(valueSettingData);

        // find option with given value.
        var option = this.options.find(option => option.value == valueSettingData.value);

        if (option == undefined) {
            // option not found. throw error
            throw new Error("Option not found: " + valueSettingData.value);
        } else {
            // select option.
            this.selectedOption = option;
        }
    }
}
