import { SettingValue, ValueSettingData } from 'src/template/SettingData';
import { ValueSetting } from './ValueSetting';

/**
 * Setting containing multiple value settings
 */
export class MultiSetting<T extends SettingValue> extends ValueSetting<ValueSettingData<T>[]> {
    private createSetting: () => ValueSetting<T>;

    private _settings: ValueSetting<T>[] = [];

    private _maxSettings: number | undefined

    get Count(): Number {
        return this._settings.length;
    }

    get settings(): ValueSetting<T>[] {
        return this._settings;
    }

    constructor(label: string, createSetting: () => ValueSetting<T>) {
        super(label);

        // value is array of values of the settings.
        this.setValue(() => this._settings.map(setting => setting.toObject()));

        this.createSetting = createSetting;

        // set valid condition to check if all settings are valid.
        this.setValidCondition(() => this._settings.every(setting => setting.checkValid()));
    }

    /**
     * Sets a bound on the maximum number of settings allowed.
     * @param maxSettings - Maximum number of settings allowed. 
     * @returns itself.
     */
    setBound(maxSettings: number): this {
        this._maxSettings = maxSettings;
        return this
    }

    /**
     * Adds a new setting to this multi setting.
     * @returns newly created setting.
     */
    public add(): ValueSetting<T> | undefined {
        // if max settings is set and the number of settings is equal to the max settings, return.
        if (this._maxSettings && this._settings.length == this._maxSettings) {
            return undefined
        }

        // add new setting.
        var setting = this.createSetting();
        this._settings.push(setting);
        return setting;
    }

    /**
     * Removes a setting from this multi setting at given index.
     * @param index - Index of the setting to be removed.
     */
    public remove(index: number) {
        this._settings.splice(index, 1);
    }

    /**
     * Swaps two settings in this multi setting at given indexes.
     * @param index1 - Index of the first setting.
     * @param index2 - Index of the second setting.
     */
    public swap(index1: number, index2: number) {
        // if indexes are out of bounds or invalid, return.
        if (index1 == this.Count || index1 == -1 ||
            index2 == this.Count || index2 == -1) return;

        // swap the settings.
        var temp = this._settings[index1];
        this._settings[index1] = this._settings[index2];
        this._settings[index2] = temp;
    }

    override fromObject(valueSettingDataMulti: ValueSettingData<ValueSettingData<T>[]>) {
        super.fromObject(valueSettingDataMulti);

        // return if there is no value.
        if (!valueSettingDataMulti.value) return;

        // create a setting for each value setting data and read data into it.
        for (let valueSettingData of valueSettingDataMulti.value) {
            var setting = this.add();
            setting!.fromObject(valueSettingData);
        }
    }
}
