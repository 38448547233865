import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlockInput } from 'src/app/Template/Cards/BlockInput';
import { HasSettings } from 'src/app/Template/HasSettings';
import { InputSetting } from 'src/app/Template/Settings/InputSetting';

@Component({
  selector: 'app-inputsetting',
  templateUrl: './inputsetting.component.html',
  styleUrls: ['./inputsetting.component.scss']
})
export class InputSettingComponent {
  @Input() setting: InputSetting;
  @Output() OpenSettingsChild = new EventEmitter<{
    settings: HasSettings;
  }>();

  setInput() {
    // emit to parent to open advanced settings menu for input
    this.OpenSettingsChild.emit({
      settings: this.setting.input as BlockInput,
    });
  }

  get inputButtonText(): string {
    // get name of building block, if building block not set return "Input"
    return this.setting.input.buildingBlockSet ? this.setting.input.buildingBlockName : "Input";
  }
}
