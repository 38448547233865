import { BlockName } from 'src/app/objects/Constants';
import { BuildingBlockData } from 'src/template/BuildingBlockData';
import { BuildingBlock } from './BuildingBlock';

/**
 * Blank Building Block
 */
export class Blank extends BuildingBlock {
  protected override _type: string = BlockName.BLANK; // type of building block

  override writeSettings(buildingBlockObject: BuildingBlockData): void {
    throw new Error('Cannot export blank building block');
  }

  override readSettings(buildingBlockObject: BuildingBlockData): void {
    // This should never happen since there shouldn't be a blank building blocks in a saved template
    throw new Error('Cannot read settings of blank building block');
  }

  private _valid: boolean = true

  override checkValid(): boolean {
    // Blank building block is always invalid.
    this._valid = false
    return false
  }

  override get valid(): boolean {
    return this._valid;
  }


}
