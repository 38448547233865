import { FilterName } from 'src/app/objects/Constants';
import { EverythingFilterData, FilterType } from "src/template/FilterData";
import { Filter } from './Filter';

// selects all cards
export class EverythingFilter extends Filter {
    protected override _filterName: string = FilterName.EVERYTHING;
    protected override _variant: FilterType.Everything = FilterType.Everything;

    override writeSettings(filterData: EverythingFilterData) {
        // nothing to write
    }

    fromObject(filterData: EverythingFilterData) {
        // nothing to read
    }
}