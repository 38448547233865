<div class="dropdown">
  <a
    class="btn btn-secondary dropdown-toggle"
    href="#"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {{ setting.valueSet ? setting.selectedOption.key : setting.placeholder }}
  </a>

  <ul class="dropdown-menu">
    <li *ngFor="let option of setting.options">
      <a
        [ngClass]="{
          'dropdown-item': true,
          active: setting.valueSet && option.key === setting.selectedOption.key
        }"
        (click)="setting.selectedOption = option"
        >{{ option.key }}</a
      >
    </li>
  </ul>
</div>
