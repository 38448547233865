import { BlockName, MAX_ANSWERS } from 'src/app/objects/Constants';
import { BlockVariant, IndividualAnswersBlockData } from 'src/template/BuildingBlockData';
import { Author, DisplayAnswers, NumberOfAnswerspp, WhoCanAnswer } from 'src/template/SettingValues';
import { InputSetting } from '../Settings/InputSetting';
import { Setting } from '../Settings/Setting';
import { CheckboxSetting } from '../Settings/ValueSettings/CheckboxSetting';
import { NumberSetting } from '../Settings/ValueSettings/NumberSetting';
import { ValueDropdownSetting } from '../Settings/ValueSettings/Options/ValueDropdownSetting';
import { TextSetting } from '../Settings/ValueSettings/TextSetting';
import { BuildingBlock } from './BuildingBlock';

/**
 * Individual answers Building Block
 */
export class IndividualAnswers extends BuildingBlock {
  protected override _type: string = BlockName.INDIVIDUAL_ANSWERS; // type of building block

  // Main Settings
  private IndividualAnswerTitle: TextSetting = new TextSetting('Text for above the screen')
    .setValidConditionNonEmpty();
  private Input: InputSetting = new InputSetting("Input", this.getBuildingBlocksBefore);


  // Advanced Settings
  private Author: ValueDropdownSetting<Author> = new ValueDropdownSetting('Author', [
    { key: "View author", value: Author.View_author },
    { key: "Make anonymous", value: Author.Make_anonymous },
  ], 'Choose option', 'View author');

  private Card_to_display_at_the_top_of_the_screen: InputSetting = new InputSetting('Card to display at the top of the screen', this.getBuildingBlocksBefore);

  private Number_of_answers_pp: ValueDropdownSetting<NumberOfAnswerspp> = new ValueDropdownSetting('Number of answers pp', [
    { key: "Unlimited", value: NumberOfAnswerspp.Unlimited },
    { key: "Amount per person", value: NumberOfAnswerspp.Amount_pp },
    { key: "Amount in total", value: NumberOfAnswerspp.Amount_total },
  ], 'Choose option', 'Unlimited');

  private Amount: NumberSetting = new NumberSetting('Amount', 1)
    .setVisibleCondition([[this.Number_of_answers_pp, (setting) => setting.value != NumberOfAnswerspp.Unlimited]])
    .setBounds(0, MAX_ANSWERS);

  private Who_can_answer: ValueDropdownSetting<WhoCanAnswer> = new ValueDropdownSetting('Who can answer', [
    { key: "Everyone", value: WhoCanAnswer.Everyone },
    { key: "Facilitator", value: WhoCanAnswer.Facilitator },
  ], 'Choose option', 'Everyone'); // Not used currently

  private Mark_duplicates: CheckboxSetting = new CheckboxSetting('Mark duplicates', false);

  private Display_answers: ValueDropdownSetting<string> = new ValueDropdownSetting('Display answers', [
    { key: "View as they are submitted", value: DisplayAnswers.View_as_they_are_submitted },
    { key: "View after all answers are submitted", value: DisplayAnswers.View_once_all_submitted },
  ], 'Choose option', 'View as they are submitted');

  private InstructionsIndividualAnswers: TextSetting = new TextSetting('Instructions');

  protected override mainSettings: Setting[] = [
    this.IndividualAnswerTitle,
    this.Input,
  ]

  protected override advancedSettings: Setting[] = [
    this.Card_to_display_at_the_top_of_the_screen,
    this.Author,
    this.Number_of_answers_pp,
    this.Amount,
    //this.Who_can_answer,
    this.Mark_duplicates,
    this.Display_answers,
    this.InstructionsIndividualAnswers,
  ]

  override writeSettings(buildingBlockObject: IndividualAnswersBlockData): void {
    // set variant to individual answers
    buildingBlockObject.variant = BlockVariant.INDIVIDUAL_ANSWERS;

    // set settings
    buildingBlockObject.settings = {
      title: this.IndividualAnswerTitle.toObject(),
      input: this.Input.toObject(),
      author: this.Author.toObject(),
      cardToDisplay: this.Card_to_display_at_the_top_of_the_screen.toObject(),
      numberOfAnswerspp: this.Number_of_answers_pp.toObject(),
      numberOfAnswersppAmount: this.Amount.toObject(),
      whoCanAnswer: this.Who_can_answer.toObject(),
      markDuplicates: this.Mark_duplicates.toObject(),
      displayAnswers: this.Display_answers.toObject(),
      instructions: this.InstructionsIndividualAnswers.toObject(),
    };
  }

  override readSettings(buildingBlockObject: IndividualAnswersBlockData): void {
    // read settings
    this.IndividualAnswerTitle.fromObject(buildingBlockObject.settings.title);
    this.Input.fromObject(buildingBlockObject.settings.input);
    this.Author.fromObject(buildingBlockObject.settings.author);
    this.Card_to_display_at_the_top_of_the_screen.fromObject(buildingBlockObject.settings.cardToDisplay);
    this.Number_of_answers_pp.fromObject(buildingBlockObject.settings.numberOfAnswerspp);
    this.Amount.fromObject(buildingBlockObject.settings.numberOfAnswersppAmount);
    this.Who_can_answer.fromObject(buildingBlockObject.settings.whoCanAnswer);
    this.Mark_duplicates.fromObject(buildingBlockObject.settings.markDuplicates);
    this.Display_answers.fromObject(buildingBlockObject.settings.displayAnswers);
    this.InstructionsIndividualAnswers.fromObject(buildingBlockObject.settings.instructions);
  }

}
