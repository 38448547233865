import { Component } from '@angular/core';

import { VCAuthService, VCTemplateService } from '@gametailors/v-cilitator-angular';

import { ActivatedRoute } from '@angular/router';
import { MANAGER_URL } from 'src/app/objects/Constants';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent {
    templateId: string;
    loading: boolean = true;

    manager_url: string = MANAGER_URL;

    constructor(
        private route: ActivatedRoute,
        public vca: VCAuthService,
        public vcTemplate: VCTemplateService,
    ) { }

    get loggedIn(): boolean {
        // check if logged in with google
        return this.vca.loggedInWithGoogle;
    }

    ngOnInit(): void {
        // get template id from url
        this.route.queryParams.subscribe(params => {
            if (params["id"]) {
                // set template id
                this.templateId = params["id"];
            }
            // set loading to false after getting template id
            this.loading = false;
        });
    }

    login() {
        // login with google
        this.vca.googleLogin();
    }
}

