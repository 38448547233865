import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';

import { AppComponent } from './app.component';

import { BuildingBlockComponent } from './components/buildingblock/buildingblock.component';
import { TemplateComponent } from './components/template/template.component';
import { SettingComponent } from './components/settings/setting/setting.component';
import { TextSettingComponent } from './components/settings/textsetting/textsetting.component';
import { AdvancedSettingsMenuComponent } from './components/advancedsettingsmenu/advancedsettingsmenu.component';
import { TimeSettingComponent } from './components/settings/timesetting/timesetting.component';
import { BlockMenuComponent } from './components/blockmenu/blockmenu.component';
import { ValueDropdownSettingComponent } from './components/settings/valuedropdownsetting/valuedropdownsetting.component';
import { MultiSettingComponent } from './components/settings/multisetting/multisetting/multisetting.component';
import { MultiSettingItemComponent } from './components/settings/multisetting/multisettingitem/multisettingitem.component';
import { NumberSettingComponent } from './components/settings/numbersetting/numbersetting.component';
import { InputSettingComponent } from './components/settings/inputsetting/inputsetting.component';
import { FilterSettingComponent } from './components/settings/filtersetting/filtersetting.component';
import { CheckboxSettingComponent } from './components/settings/checkboxsetting/checkboxsetting.component';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import {
    ServiceWorkerModule,
} from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire/compat';
import { VCilitatorModule } from '@gametailors/v-cilitator-angular';
import { providers } from './providers';
import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './components/main/main.component';
import { SettingDropdownSettingComponent } from './components/settings/settingdropdownsetting/settingdropdownsetting.component';

@NgModule({
    declarations: [
        AppComponent,
        BuildingBlockComponent,
        TemplateComponent,
        SettingComponent,
        TextSettingComponent,
        AdvancedSettingsMenuComponent,
        TimeSettingComponent,
        BlockMenuComponent,
        ValueDropdownSettingComponent,
        MultiSettingComponent,
        MultiSettingItemComponent,
        NumberSettingComponent,
        InputSettingComponent,
        FilterSettingComponent,
        CheckboxSettingComponent,
        MainComponent,
        SettingDropdownSettingComponent
    ],
    imports: [
        BrowserModule,
        MatDialogModule,
        AngularFireModule.initializeApp(environment.firebase),
        provideFirebaseApp(() => initializeApp(environment.firebase)), //ng g environments -> add firebase config to it which you can find in the firebase console
        provideAuth(() => getAuth()),
        provideDatabase(() => getDatabase()),
        provideFunctions(() => {
            const f = getFunctions();
            f.region = 'europe-west1';
            return f;
        }),
        VCilitatorModule,
        ServiceWorkerModule.register('/firebase-messaging-sw.js', {
            enabled: true,
        }),
        AppRoutingModule,
    ],
    providers: providers,
    bootstrap: [AppComponent]
})
export class AppModule { }
