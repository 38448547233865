import { Component, Input } from '@angular/core';
import { FilterSetting } from 'src/app/Template/Settings/ValueSettings/FilterSetting';

@Component({
  selector: 'app-filtersetting',
  templateUrl: './filtersetting.component.html',
  styleUrls: ['./filtersetting.component.scss']
})
export class FilterSettingComponent {
  @Input() setting: FilterSetting;
}
