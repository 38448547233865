import { Component, Input } from '@angular/core';
import { ValueDropdownSetting } from '../../../Template/Settings/ValueSettings/Options/ValueDropdownSetting';

@Component({
  selector: 'app-valuedropdownsetting',
  templateUrl: './valuedropdownsetting.component.html',
  styleUrls: ['./valuedropdownsetting.component.scss']
})
export class ValueDropdownSettingComponent {
  @Input() setting: ValueDropdownSetting<any>;
}
