<div *ngIf="setting.selectedOption != undefined; else noOption">
    <app-setting [setting]="setting.settingOption!" [label]="false"></app-setting>
</div>

<ng-template #noOption>
    <div class="dropdown">
        <a
            class="btn btn-secondary dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
            {{ setting.placeholder }}
        </a>
    
        <ul class="dropdown-menu">
            <li *ngFor="let option of setting.options">
            <a
                [ngClass]="{
                'dropdown-item': true,
                active: setting.selectedOption != undefined && option.key === setting.selectedOption.key
                }"
                (click)="setting.selectedOption = option"
                >{{ option.key }}</a
            >
            </li>
        </ul>
    </div>
</ng-template>
