import { FlowConnectionName } from "src/app/objects/Constants";
import { FlowConnectionData, FlowConnectionVariant, ManualFlowConnection } from "src/template/FlowConnectionData";
import { Setting } from "../Settings/Setting";
import { FlowConnection } from "./FlowConnection";

/**
 * Flow connection for proceeding when facilitator approves.
 */
export class FacilitatorApproves extends FlowConnection {
    protected _name = FlowConnectionName.FACILITATOR_APPROVES;

    getSettings(): Setting[] {
        return [];
    };

    toObject(): FlowConnectionData {
        return {
            source: "",
            target: "",
            variant: FlowConnectionVariant.FacilitatorApproves
        }
    }
    fromObject(flowConnectionObject: ManualFlowConnection) {
        // no setting to read from
    }
}