import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HasSettings } from 'src/app/Template/HasSettings';
import { Setting } from 'src/app/Template/Settings/Setting';

@Component({
  selector: 'app-advancedsettingsmenu',
  templateUrl: './advancedsettingsmenu.component.html',
  styleUrls: ['./advancedsettingsmenu.component.scss']
})
export class AdvancedSettingsMenuComponent {
  // array of HasSettings objects, last one is the current advanced settings displayed
  advancedSettings: HasSettings[] = [];

  get settings(): Setting[] {
    return this.advancedSettings[this.advancedSettings.length - 1].getAdvancedSettings();
  }

  get header(): string {
    return this.advancedSettings[this.advancedSettings.length - 1].advandedSettingsHeader;
  }

  constructor(public dialogRef: MatDialogRef<AdvancedSettingsMenuComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.advancedSettings.push(data.advancedSettings);
  }

  addAdvancedSettings(advancedSettings: HasSettings) {
    // add another HasSettings to the advanced settings
    this.advancedSettings.push(advancedSettings);
  }

  openSettings(action: { settings: HasSettings }) {
    this.addAdvancedSettings(action.settings);
  }

  back() {
    // check if there are more advanced settings to go back to
    // otherwise close menu
    if (this.advancedSettings.length > 1) {
      this.advancedSettings.pop();
    } else {
      this.dialogRef.close();
    }
  }
}
