import { FilterName } from "src/app/objects/Constants";
import { AtLeastNVotesFilterData, FilterType } from "src/template/FilterData";
import { NumberSetting } from '../../Settings/ValueSettings/NumberSetting';
import { ValueSetting } from '../../Settings/ValueSettings/ValueSetting';
import { Filter } from "./Filter";


// selects cards with at least x votes
// this is the same as HasVotes, if x = 1
export class MinFilter extends Filter {
    protected override _filterName: string = FilterName.MINVOTES;
    protected override _variant: FilterType.MinVotes = FilterType.MinVotes;
    protected override _settings: ValueSetting<any>[] = [
        new NumberSetting('x', 1) // Amount setting
    ];

    override writeSettings(filterData: AtLeastNVotesFilterData) {
        filterData.amount = this._settings[0].value;
    }

    fromObject(filterData: AtLeastNVotesFilterData) {
        this._settings[0].setValue(filterData.amount);
    }
}